<template>
  <div class="relation-create__form">
    <div class="relation-create__row">
      <p>Контактное лицо:</p>
      <v-select
        v-if="clientsContact.length > 0"
        :disabled="isEditableRelation"
        v-model="relation.contact"
        :class="relation.contact === null ? 'red-border' : ''"
        label="name"
        :options="clientsContact"
      ></v-select>
    </div>
    <div v-if="isCreatingMeet" class="relation-create__row">
      <p>Дата и время встречи:</p>
      <el-date-picker
        v-model="relation.mittingTimeStart"
        :disabled="isEditableRelation"
        :class="relation.mittingTimeStart === null ? 'red-border' : ''"
        type="datetime"
        format="dd.MM.yyyy HH:mm"
        size="small"
        value-format="yyyyMMddHHmmss"
        placeholder="Старт"
      >
      </el-date-picker>
      <el-date-picker
        v-model="relation.mittingTimeEnd"
        :disabled="isEditableRelation"
        :class="relation.mittingTimeEnd === null ? 'red-border' : ''"
        type="datetime"
        format="dd.MM.yyyy HH:mm"
        size="small"
        value-format="yyyyMMddHHmmss"
        placeholder="Конец"
      >
      </el-date-picker>
    </div>
    <div v-if="isCreatingMeet" class="relation-create__row">
      <p>Тип встречи:</p>
      <v-select
        v-model="relation.mittingType"
        :disabled="isEditableRelation"
        :class="relation.mittingType === null ? 'red-border' : ''"
        :options="mittingTypes"
      ></v-select>
    </div>
    <div v-if="isCreatingMeet" class="relation-create__row">
      <p>Место встречи:</p>
      <el-input
        size="small"
        :disabled="isEditableRelation"
        v-model="relation.mittingPlace"
      />
    </div>
    <div v-if="isCreatingMeet === false" class="relation-create__row">
      <p>Дата звонка:</p>
      <el-date-picker
        v-model="relation.phoneTime"
        :disabled="isEditableRelation"
        :class="relation.phoneTime === null ? 'red-border' : ''"
        format="dd.MM.yyyy HH:mm"
        size="small"
        value-format="yyyyMMddHHmmss"
        type="datetime"
      />
    </div>
    <div class="relation-create__row">
      <p>Тема:</p>
      <el-input
        :class="relation.theme.length < 5 ? 'red-border' : ''"
        :disabled="isEditableRelation"
        size="small"
        v-model="relation.theme"
      />
    </div>
    <div class="relation-create__row">
      <el-tabs type="border-card">
        <el-tab-pane label="Описание">
          <el-input
            :class="relation.description.length < 5 ? 'red-border' : ''"
            :disabled="isEditableRelation"
            type="textarea"
            size="small"
            rows="4"
            resize="none"
            placeholder="Введите описание"
            v-model="relation.description"
          />
        </el-tab-pane>
        <el-tab-pane label="Комментарии">
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in relation.comments"
              :key="index"
              :timestamp="item.date | dateConvert"
              placement="top"
            >
              <el-card>
                <p>{{ item.text }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <el-row class="relation-create__row-comment">
            <el-input
              v-model="currentComment"
              size="small"
              @keyup.enter.native="addCommentToRelation"
              placeholder="Введите комментарий"
            />
            <el-button
              :disabled="currentComment.length < 4"
              type="success"
              icon="el-icon-s-promotion"
              circle
              @click="addCommentToRelation"
            ></el-button>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <div class="relation-create__row">
        <p>Рассмотреть после:</p>
        <el-date-picker
          :disabled="relation.isReviewed"
          v-model="relation.reviewDate"
          format="dd.MM.yyyy HH:mm"
          size="small"
          value-format="yyyyMMddHHmmss"
          type="datetime"
        >
        </el-date-picker>
      </div>
      <div class="relation-create__row">
        <el-checkbox v-model="relation.isReviewed">Рассмотренно</el-checkbox>
      </div>
    </div>
    <div class="relation-create__row relation-create__footer">
      <el-button
        v-if="!isEditableRelation"
        size="small"
        type="warning"
        @click="emptyForm"
        >Очистить форму</el-button
      >
      <el-button
        :disabled="!validateFormNewRelation"
        :loading="isSentingRelation"
        size="small"
        type="success"
        @click="createRelation"
      >
        Сохранить</el-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { ADRESS } from "@/store/actions/auth";
export default {
  name: "vRelation",
  data() {
    return {
      relation: {
        theme: "",
        description: "",
        contact: null,
        reviewDate: null,
        isReviewed: false,
        comments: [],
        mittingTime: null,
        mittingTimeStart: null,
        mittingTimeEnd: null,
        mittingType: null,
        mittingPlace: "",
        phoneTime: null,
        mittingTypes: ["Выездная встреча", "В офисе"],
        currentComment: "",
      },
      mittingTypes: ["Выездная встреча", "В офисе"],
      currentComment: "",
      isSentingRelation: false,
    };
  },
  props: {
    clientsContact: Array,
    activities: String,
    isCreatingMeet: Boolean,
    uikCompany: String,
    isEditableRelation: Boolean,
    currentRelation: Object,
  },
  created() {},
  mounted() {
    this.emptyForm();
    if (this.isEditableRelation === true) {
      this.getRelationData();
    }
  },
  filters: {
    dateConvert(value) {
      return moment(value, "YYYYMMDDHHmmss").format("DD.MM.YYYY HH:mm");
    },
  },
  computed: {
    validateFormNewRelation() {
      return (
        this.relation.theme.length > 4 &&
        this.relation.description.length > 4 &&
        this.relation.contact !== null &&
        ((this.relation.mittingTimeStart !== null &&
          this.relation.mittingTimeEnd !== null &&
          this.relation.mittingType !== null) ||
          this.relation.phoneTime !== null ||
          this.isCreatingMeet === null)
      );
    },
    isCreatingRelation() {
      return this.$store.getters.isCreatingRelation;
    },
  },
  watch: {
    isCreatingRelation(value) {
      if (value === false) {
        this.emptyForm();
      } else if (value === true) {
        if (this.isEditableRelation === true) {
          this.getRelationData();
        }
      }
    },
  },
  methods: {
    addCommentToRelation() {
      if (this.currentComment.length > 3) {
        const comment = {
          text: this.currentComment,
          date: moment().format("YYYYMMDDHHmmss"),
          new: true,
        };
        this.currentComment = "";
        this.relation.comments.push(comment);
      }
    },
    emptyForm() {
      this.relation.theme = "";
      this.relation.description = "";
      this.relation.contact = null;
      this.relation.reviewDate = null;
      this.relation.isReviewed = false;
      this.relation.mittingTimeStart = null;
      this.relation.mittingTimeEnd = null;
      this.relation.mittingType = null;
      this.relation.mittingPlace = "";
      this.relation.phoneTime = null;
      this.relation.comments = [];
    },
    createRelation() {
      this.isSentingRelation = true;
      if (this.isEditableRelation === false) {
        this.relation.mittingTime = [
          this.relation.mittingTimeStart,
          this.relation.mittingTimeEnd,
        ];
        axios({
          method: "post",
          url: ADRESS + "createrelation",
          data: {
            ...this.relation,
            uik: this.uikCompany,
          },
          headers: {
            Authorization: this.activities,
          },
        })
          .then((response) => {
            this.isSentingRelation = false;
            this.$notify.success({
              title: "Сохранено",
              message: response.status,
            });
            const uik = response.data.uik;
            this.$emit("addNewRelation", { ...this.relation, uik: uik });
            this.emptyForm();
          })
          .catch((err) => {
            console.log(err);
            this.isSentingRelation = false;
            this.$notify.error({
              title: "Ошибка",
              message: err,
            });
          });
      } else {
        axios({
          method: "post",
          url: ADRESS + "editrelation",
          data: {
            ...this.relation,
            uikrelation: this.currentRelation["УИК"],
            type: this.currentRelation["Тип"],
            uik: this.uikCompany,
          },
          headers: {
            Authorization: this.activities,
          },
        })
          .then((response) => {
            this.isSentingRelation = false;
            this.$notify.success({
              title: "Сохранено",
              message: response.status,
            });
            const uik = response.data.uik;
            this.$emit("addNewRelation", { ...this.relation, uik: uik });
            this.emptyForm();
          })
          .catch((err) => {
            console.log(err);
            this.isSentingRelation = false;
            this.$notify.error({
              title: "Ошибка",
              message: err,
            });
          });
      }
    },
    getRelationData() {
      axios({
        method: "get",
        url: ADRESS + "viewrelation",
        params: {
          uikrelation: this.currentRelation["УИК"],
          type: this.currentRelation["Тип"],
          uik: this.uikCompany,
        },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          Object.assign(this.$data.relation, response.data);
          if (this.relation.mittingTime !== null) {
            this.relation.mittingTimeStart = this.relation.mittingTime[0];
            this.relation.mittingTimeEnd = this.relation.mittingTime[1];
          }
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
          this.$emit("closeDialog");
        });
    },
  },
};
</script>

<style lang="scss">
</style>