<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="list-agreements">
    <div class="list-agreements-container" v-if="agreements !== null">
      <el-row type="flex" class="list-agreements__search">
        <el-input v-model="search" size="mini" placeholder="Поиск" />
      </el-row>
      <el-table class="list-agreements__table" @row-click="onClickInAgreement" :row-class-name="classForRow" :data="
        agreements.filter(
          (data) =>
            !search ||
            data['Автор'].toLowerCase().includes(search.toLowerCase())
        )
      " :default-sort="{ prop: 'date', order: 'descending' }" stripe style="width: 100%">
        <el-table-column prop="date" min-width="120" width="120" sortable :sort-method="sortDate" label="Дата">
        </el-table-column>
        <el-table-column label="Название" min-width="160">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <span v-if="scope.row['Задача'] !== null">{{
              scope.row["Задача"]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Автор" min-width="130">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <span v-if="scope.row['Автор'] !== null">{{
              scope.row["Автор"]
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <vAgreementView :propAgreement="currentAgreement" :activities="activities" @closeDialog="currentAgreement = null"
        @accept="removeAgreement" />
      <vTaskView :propTask="currentAgreement" :activities="activities" @closeDialog="currentAgreement = null"
        @deleteTask="removeAgreement" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vAgreementView from "./components/vAgreementView.vue";
import moment from "moment";
import { ADRESS } from "@/store/actions/auth";
import Fetch from "./mixins/Fetch";
import { handelerErrors } from "@/plugins/utils"
import vTaskView from "./components/vTaskView.vue";

export default {
  name: "Agreements",
  components: {
    vAgreementView,
    vTaskView
  },
  mixins: [Fetch],
  data() {
    return {
      agreements: null,
      search: "",
      fullscreenLoading: false,
      activities: null,
      userId: "",
      isDialogShow: false,
      currentAgreement: null,
      lists: null,
    };
  },
  methods: {
    fetchData() {
      this.activities = localStorage.getItem("user-token");
      this.getListAgreements();
    },
    sortDate(a, b) {
      return (
        moment(a, "DD.MM.YYYY HH:mm").unix() -
        moment(b, "DD.MM.YYYY HH:mm").unix()
      );
    },
    convertDate(date) {
      return moment(date, "DD.MM.YYYY HH:mm:ss").format("DD.MM.YYYY HH:mm");
    },
    getListAgreements() {
      this.fullscreenLoading = true;
      axios
        .get(`${ADRESS}get-list-reconciliation`, {
          headers: { Authorization: this.activities },
        })
        .then((response) => {
          this.agreements = response.data;
          this.agreements = this.agreements.map((agreement) => {
            agreement.date = this.convertDate(agreement["Дата"]);
            return agreement;
          });
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    onClickInAgreement(row) {
      this.currentAgreement = row;
    },
    classForRow(row) {
      switch (row.row["Статус"]) {
        case "Просрочено":
          return "agreements-row__expired";
        default:
          return "agreements-row__normal";
      }
    },
    removeAgreement(uik) {
      this.agreements = this.agreements.filter((item) => item["УИК"] !== uik);
    },
  },
};
</script>

<style lang="scss">
.list-agreements-container {
  margin-top: 15px;
}

.list-agreements {
  .list-agreements__table {
    color: #000000;
    font-size: 12px;

    tr {
      cursor: pointer;

      div {
        white-space: nowrap;
        overflow: hidden;
      }
    }

    tr.agreements-row__expired {
      background-color: #f58e8e;

      td {
        background-color: #f58e8e;
      }
    }
  }
}
</style>
