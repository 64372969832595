<template>
  <div class="login-form-container">
    <div class="login-form">
      <el-form @submit.native.prevent="login">
        <img class="login-logo" src="/assets/images/logo_trr.svg" />
        <el-input v-model="username" v-mask="'+7 (###)###-####'" placeholder="Корп. номер телефона"></el-input>
        <el-input required type="password" v-model="password" placeholder="Пароль от CRM" show-password />
        <el-button :disabled="disableButton" :loading="loading" type="primary" native-type="submit">Войти</el-button>
        <div>
          <strong>{{ texterr }}</strong>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST, ADRESS } from "@/store/actions/auth";
import axios from "axios";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      spinner: false,
      showtexterr: false,
      texterr: "",
      loading: false,
    };
  },
  computed: {
    disableButton() {
      return this.username.length !== 16 || this.password == "";
    },
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "isDiler",
    ]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
      name: (state) => `${state.user.profile.name}`,
    }),
  },
  methods: {
    login() {
      this.loading = true;
      const { username, password } = this;
      const login = username.login;
      this.spinner = true;
      this.$store
        .dispatch(AUTH_REQUEST, { username, password })
        .then(() => {
          setTimeout(() => {
            if (this.$store.getters.isDiler) {
              this.$router.push("/check-firm/");
            } else {
              this.$router.push("/");
            }
          }, 200);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    showerressage(err) {
      this.showtexterr = true;
      this.texterr = "Ошибка авторизации!!!";
      console.log(err.response.status);
    },
  },
};
</script>

<style scoped lang="scss">
.login-form-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form {
  width: 100%;
  max-width: 600px;
}

.login-form * {
  margin: 5px 0;
}

.el-button {
  display: block;
}

.el-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-button {
    width: 90%;
    max-width: 400px;
  }
}

.el-form>div {
  width: 90%;
  max-width: 400px;
}

.login-logo {
  width: 80%;
  max-width: 350px;
  margin-bottom: 30px;
}
</style>