export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
// export const ADRESS = "https://crm.liftnet.ru/tr-lift/crmapi/hs/crmapi/";

let ADRESS = "/tr-lift/crmapi/hs/crmapi/" // БОЕВАЯ
const location = window.location.hostname
console.log(location)

if (location.includes("crmtest.liftnet.ru") || location.includes("localhost")) {
    ADRESS = "http://v8.tr-lift.ru:8445/crmapidev/hs/crmapi/"; // ПОТЕСТИТЬ ДЕВ
}

// ADRESS = 'http://it-4/tr_crm_dev/hs/crmapi/'
// ADRESS = "http://v8.tr-lift.ru:8445/crmapi/hs/crmapi/"; // РАБОЧАЯ ПОТЕСТИТЬ

export { ADRESS }

// export const ADRESS = "http://10.116.1.67/tr_crm/hs/crmapi/";
// export const BASE_ADDRESS = "http://v8.tr-lift.ru:8445/crmapi/ru_RU/e1cib/"
// export const ADRESS = "http://v8.tr-lift.ru:8445/crmapidev/hs/crmapi/";
// export const BASE_ADDRESS = "http://v8.tr-lift.ru:8445/crmapidev/ru_RU/e1cib/"
// export const ADRESS = "http://10.1.1.29/crmapi/hs/crmapi/";
// export const BASE_ADDRESS = "http://10.1.1.29/crmapi/ru_RU/e1cib/"
export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";