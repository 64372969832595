<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="list-technics">
    <v-select v-show="!isDiler || (this.user_directions !== null && this.user_directions.length > 1)" v-model="direction"
      label="name" :options="directions" @option:selected="search" placeholder="Выберите направление"></v-select>
    <v-select v-if="brands !== null" @option:selected="onChangeBrand" v-model="brand" label="name" :options="brands"
      placeholder="Выберите бренд"></v-select>
    <v-select v-if="models !== null" v-model="product" label="name" :options="models"
      placeholder="Выберите модель"></v-select>
    <v-select v-if="masts !== null && masts.length > 1" v-model="mast" label="name" :options="masts"
      placeholder="Выберите мачту"></v-select>
    <v-select v-if="branchs !== null && branchs.length > 1" v-model="branch" label="name" :options="branchs"
      placeholder="Выберите размещение"></v-select>
    <v-select v-if="fixedstatuses !== null && fixedstatuses.length > 1" v-model="fixedstatus" label="name"
      :options="fixedstatuses" placeholder="Статус закрепления"></v-select>

      <el-button @click="() => {
        onlyfixed = !onlyfixed
        search()
      }"
      >
        Закрепленная техника за мной
      </el-button>

    <div class="list-technics__collapse-title">
      <div class="list-technics__collapse-product">
        <strong>Название</strong>
      </div>
      <div class="list-technics__collapse-status">
        <strong>Статус</strong>
      </div>
    </div>
    <el-collapse class="list-technics__collapse">
      <el-collapse-item v-for="(item, index) in filterProductList" :key="index" :class="index % 2 == 0 ? 'striped' : ''"
        :name="index">
        <template slot="title">
          <div class="list-technics__collapse-title">
            <div class="list-technics__collapse-product">
              <span>{{ item.brand }} {{ item.model }} {{ item.mast }}</span>
            </div>
            <div class="list-technics__collapse-status">
              <span>{{ item.status }}
                <template v-if="item.status !== 'На складе' && item.receipt_date.length > 0
                  ">
                  ({{ item.receipt_date }})
                </template>
                <template v-if="item.status !== item.sklad">
                  ({{ item.sklad }})</template>
              </span>
            </div>
          </div>
        </template>
        <ul>
          <li v-if="item.name">Название: {{ item.name }}</li>
          <li v-if="item.fixedstatus">
            Статус закрепления: {{ item.fixedstatus }}
          </li>
          <li v-if="item.order_date">Дата заказа: {{ item.order_date }}</li>
          <li v-if="item.shipment_date">
            Дата готовоности на заводе: {{ item.shipment_date }}
          </li>
          <li v-if="item.receipt_date">
            Планируема дата таможенной очистки: {{ item.receipt_date }}
          </li>
          <li v-if="item.sklad">Размещение: {{ item.sklad }}</li>
          <li v-if="item.comment_logist">
            Комментарий логиста: {{ item.comment_logist }}
          </li>
          <li v-if="item.number">Серийный номер: {{ item.number }}</li>
          <li v-if="item.cost">Сумма затрат: {{ item.cost }}</li>
          <li v-if="item.prise">Цена: {{ item.prise }}</li>
          <li v-if="item.uts">Стоимость УТС: {{ item.uts }} рублей</li>
          <li v-if="item.compound">Оснащение: {{ item.compound }}</li>
          <li v-if="item.comment">Примечание: {{ item.comment }}</li>
          <li v-if="item.mast">Мачта: {{ item.mast }}</li>
          <li v-if="item.akb">Акб: {{ item.akb }}</li>
          <li v-if="item.zu">Зу: {{ item.zu }}</li>
          <li v-if="item.akb_type">Тип акб: {{ item.akb_type }}</li>
          <li v-if="item.capacity">Грузоподъемность: {{ item.capacity }}</li>
          <li v-if="item.lift">Высота подъема: {{ item.lift }}</li>
        </ul>
      </el-collapse-item>
    </el-collapse>
    <el-alert
							v-show="errorMessage !== null && errorMessage?.Text"
							type="error"
							title="Ошибка"
							show-icon
							:closable="false"
						>
							<span style="font-size: 16px">
								{{ errorMessage?.Text }}
							</span>
		</el-alert>
  </div>
</template>


<script>
import axios from "axios";
import moment from "moment";
import { ADRESS } from "@/store/actions/auth";
import Fetch from "./mixins/Fetch";
import { mapGetters } from "vuex";
import { handelerErrors } from "@/plugins/utils"
import { Notification } from 'element-ui';


export default {
  name: "Technics",
  components: {},
  mixins: [Fetch],
  data() {
    return {
      fullscreenLoading: false,
      activities: null,
      userId: "",
      brand: null,
      product: null,
      mast: null,
      branch: null,
      product_list: null,
      direction: null,
      fixedstatus: null,
      errorMessage: null,
      directions: [
        {
          name: "Вилочные погрузчики",
          id: 1,
        },
        {
          name: "Дорожно-строительная техника",
          id: 2,
        },
        {
          name: "Складская техника",
          id: 3,
        },
      ],
      onlyfixed: 0
    };
  },
  computed: {
    ...mapGetters(["isDiler", "user_directions"]),
    brands() {
      if (this.product_list !== null) {
        let products = this.product_list;
        if (this.product !== null) {
          products = products.filter((a) => a.model === this.product);
        }
        if (this.mast !== null) {
          products = products.filter((a) => a.mast === this.mast);
        }
        if (this.branch !== null) {
          products = products.filter((a) => a.sklad === this.branch);
        }
        return [...new Set(products.map((a) => a.brand))].sort((a, b) =>
          a.localeCompare(b)
        );
      } else {
        return null;
      }
    },
    models() {
      if (this.product_list !== null) {
        let products = this.product_list;
        if (this.brand !== null) {
          products = products.filter((a) => a.brand === this.brand);
        }
        if (this.mast !== null) {
          products = products.filter((a) => a.mast === this.mast);
        }
        if (this.branch !== null) {
          products = products.filter((a) => a.sklad === this.branch);
        }
        if (this.fixedstatus !== null) {
          products = products.filter((a) => a.fixedstatus === this.fixedstatus);
        }
        return [...new Set(products.map((a) => a.model))].sort((a, b) =>
          a.localeCompare(b)
        );
      } else {
        return null;
      }
    },
    masts() {
      if (this.product_list !== null) {
        let products = this.product_list;
        if (this.brand !== null) {
          products = products.filter((a) => a.brand === this.brand);
        }
        if (this.product !== null) {
          products = products.filter((a) => a.model === this.product);
        }
        if (this.branch !== null) {
          products = products.filter((a) => a.sklad === this.branch);
        }
        if (this.fixedstatus !== null) {
          products = products.filter((a) => a.fixedstatus === this.fixedstatus);
        }
        return [...new Set(products.map((a) => a.mast))].sort((a, b) =>
          a.localeCompare(b)
        );
      } else {
        return null;
      }
    },
    branchs() {
      if (this.product_list !== null) {
        let products = this.product_list;
        if (this.brand !== null) {
          products = products.filter((a) => a.brand === this.brand);
        }
        if (this.product !== null) {
          products = products.filter((a) => a.model === this.product);
        }
        if (this.mast !== null) {
          products = products.filter((a) => a.mast === this.mast);
        }
        if (this.fixedstatus !== null) {
          products = products.filter((a) => a.fixedstatus === this.fixedstatus);
        }

        return [...new Set(products.map((a) => a.sklad))].sort((a, b) =>
          a.localeCompare(b)
        );
      } else {
        return null;
      }
    },
    fixedstatuses() {
      if (this.product_list !== null) {
        let products = this.product_list;
        if (this.brand !== null) {
          products = products.filter((a) => a.brand === this.brand);
        }
        if (this.product !== null) {
          products = products.filter((a) => a.model === this.product);
        }
        if (this.mast !== null) {
          products = products.filter((a) => a.mast === this.mast);
        }
        return [...new Set(products.map((a) => a.fixedstatus))].sort((a, b) =>
          a.localeCompare(b)
        );
      } else {
        return null;
      }
    },
    filterProductList() {
      if (this.product_list !== null) {
        let products = this.product_list;
        if (this.brand !== null) {
          products = products.filter((a) => a.brand === this.brand);
        }
        if (this.product !== null) {
          products = products.filter((a) => a.model === this.product);
        }
        if (this.mast !== null) {
          products = products.filter((a) => a.mast === this.mast);
        }
        if (this.branch !== null) {
          products = products.filter((a) => a.sklad === this.branch);
        }
        if (this.fixedstatus !== null) {
          products = products.filter((a) => a.fixedstatus === this.fixedstatus);
        }

        return products;
      } else {
        return null;
      }
    },
  },
  methods: {
    async FF() {
      const resp = await fetch("https://www.liftnet.ru/get-models")
      console.log(resp)
    },

    fetchData() {
      this.activities = localStorage.getItem("user-token");
      if (this.user_directions.length > 1) {
        let related_ids = []
        this.user_directions.forEach(element => {
          if (element == "000000001") {
            related_ids.push(1)
          }
          if (element == "000000003") {
            related_ids.push(2)
          }
          if (element == "000000002") {
            related_ids.push(3)
          }
        });
        this.directions = this.directions.filter(item => related_ids.includes(item.id))
      }

      if (this.isDiler && this.user_directions !== null && this.user_directions.length == 1) {
        if (this.user_directions[0] == "000000001") {
          this.direction = {
            id: 1,
            name: "Вилочные погрузчики",
          };
        }
        if (this.user_directions[0] == "000000003") {
          this.direction = {
            id: 2,
            name: "Дорожно-строительная техника",
          };
        }
        this.search();
      }
    },
    search() {
      this.fullscreenLoading = true;
      this.brand = null;
      this.product = null;
      this.mast = null;
      this.branch = null;

      if(!this.direction){
        this.fullscreenLoading = false;
        this.errorMessage =
					Notification.error({
						title: "Ошибка",
						message: "Выберите направление",
					})
        return
      }

      axios
        .get(`${ADRESS}get-tock-availability`, {
          headers: { Authorization: this.activities },
          params: {
            type: this.direction.id,
            onlyfixed: this.onlyfixed ? 1 : 0
          },
        })
        .then((response) => {
          this.product_list = response.data.sort(this.sortingForStatusAndDate);
          this.product_list = this.product_list.map((item) => {
            return { ...item, mast: item.mast.replace(/ /g, "") };
          });
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    sortingForStatusAndDate(a, b) {
      let x = 0;
      switch (a.status) {
        case "На складе":
          x = 1;
          break;
        case "В пути":
          x = 10;
          break;
        default:
          x = 100;
          break;
      }

      let y = 0;
      switch (b.status) {
        case "На складе":
          y = 10;
          break;
        case "В пути":
          y = 100;
          break;
        default:
          y = 1000;
          break;
      }

      let left =
        a.receipt_date === ""
          ? Infinity
          : moment(a.receipt_date, "DD.MM.YYYY").unix();
      let right =
        b.receipt_date === ""
          ? Infinity
          : moment(b.receipt_date, "DD.MM.YYYY").unix();

      if (a.status == "В пути") {
        if (b.status !== a.status) {
          return x - y;
        } else {
          return left - right;
        }
      }
      if (b.status == "В пути") {
        if (a.status !== b.status) {
          return x - y;
        } else {
          return left - right;
        }
      }

      if (a.status == "В производстве") {
        if (b.status !== a.status) {
          return x - y;
        } else {
          return left - right;
        }
      }
      if (b.status == "В производстве") {
        if (a.status !== b.status) {
          return x - y;
        } else {
          return left - right;
        }
      }
      return x - y;
    },
    onChangeBrand() {
      if (this.product !== null) {
        this.product = null;
      }
    },
  },
};
</script>

<style lang="scss">
.list-technics {
  padding: 0 15px;

  .v-select {
    margin-bottom: 10px;
  }
}

.list-technics__table {
  thead {
    color: #000;
    font-size: 12px;

    .cell {
      padding: 0 6px;
    }
  }

  .cell {
    font-size: 11px;
    padding: 0 6px;
  }

  .el-table_1_column_2 .cell {
    white-space: nowrap;
  }
}

.list-technics__collapse {
  .el-collapse-item__header {
    color: #000;
    height: auto;
    line-height: 14px;

    .el-collapse-item__arrow {
      display: none;
    }
  }

  .striped .el-collapse-item__header {
    background-color: #fafafa;
  }

  .is-active .list-technics__collapse-title span {
    font-weight: bold;
  }

  .el-collapse-item__content {
    color: #000;
    padding-left: 22px;
    padding-right: 10px;
  }

  li {
    margin-bottom: 5px;
  }

  .el-collapse-item.is-active {
    margin: 15px 0;
  }

  .el-collapse-item.is-active * {
    background-color: #fcf7e9;
  }

  @media (min-width: 680px) {
    * {
      font-size: 14px;
      line-height: 18px;
    }

    .list-technics__collapse-status {
      min-width: 180px;
      width: 180px;
    }
  }
}

.list-technics__collapse-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 11px;
  padding: 10px 0;
}

.list-technics__collapse-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 7px;
}

.list-technics__collapse-status {
  min-width: 100px;
  width: 100px;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
}
</style>
