import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, ADRESS, USER_REQUEST } from "../actions/auth";
import router from '@/router'

import axios from 'axios';
// axios.defaults.proxy.host = "http://www.demoapp.com"
const state = {
    token: localStorage.getItem("user-token") || "",
    status: "",
    hasLoadedOnce: false,
    isDiler: localStorage.getItem("isDiler") == "true",
    user_directions: localStorage.getItem("user_directions") !== null ? localStorage.getItem("user_directions").split("|") : null,
    // fio: localStorage.getItem("user-fio") || "",
    // mail: localStorage.getItem("user-mail") || "",
    // phone: localStorage.getItem("user-phone") || "",
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    fio: state => state.fio,
    mail: state => state.mail,
    phone: state => state.phone,
    isDiler: state => state.isDiler,
    user_directions: state => state.user_directions,
};

const actions = {
    [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
        return new Promise((resolve, reject) => {
            const encoded = encodeURI(user.username.replace(/[^\w\s]/gi, '').replace('7 ', ''));
            const encodedPassword = encodeURI(user.password);
            var encodedString = btoa(encoded + ":" + encodedPassword);
            commit(AUTH_REQUEST);
            axios.post(ADRESS + 'auth', {
                    clientID: 'Basic ' + encodedString
                }, {
                    headers: { 'clientID': 'Basic ' + encodedString, "X-CSRFToken": window.csrf_token },
                }).then(resp => {
                    localStorage.setItem("user-token", resp.data.token);
                    window.resp = resp.data;
                    if (Object.prototype.hasOwnProperty.call(resp.data, "fio")) {
                        localStorage.setItem("user-fio", resp.data.fio);
                        localStorage.setItem("used-id", resp.data.user_id);
                    }
                    if (Object.prototype.hasOwnProperty.call(resp.data, "isDiler")) {
                        localStorage.setItem("isDiler", resp.data.isDiler);
                        commit("updateDiler", resp.data.isDiler);
                    }
                    if (Object.prototype.hasOwnProperty.call(resp.data, "directions")) {
                        localStorage.setItem("user_directions", resp.data.directions.join("|"));
                        commit("updateDirections", resp.data.directions);
                    }
                    if (Object.prototype.hasOwnProperty.call(resp.data, "contacts")) {
                        const arr = resp.data.contacts;
                        let phone = '';
                        arr.forEach(element => {
                            if (element['Вид'] === "Электронная почта") {
                                localStorage.setItem("user-mail", element['АдресЭП']);
                            }
                            if (element['Вид'] === "Корпоративный телефон") {
                                phone = element['НомерТелефона'];
                            }
                        });
                        if (phone.length > 3) {
                            localStorage.setItem("user-phone", phone);
                        } else {
                            localStorage.setItem("user-phone", 'Нет корп телефона');
                        }
                    }
                    commit(AUTH_SUCCESS, resp.data);
                    dispatch(USER_REQUEST);
                    resolve(resp.data);
                })
                .catch(err => {
                    console.log(err);
                    commit(AUTH_ERROR, err);
                    localStorage.removeItem("user-token");
                    localStorage.removeItem("user-fio");
                    localStorage.removeItem("user-mail");
                    localStorage.removeItem("user-phone");
                    localStorage.removeItem("used-id");
                    localStorage.removeItem("isDiler");
                    localStorage.removeItem("user_directions");
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise(resolve => {
            console.log("AUTH_LOGOUT");
            commit(AUTH_LOGOUT);
            localStorage.removeItem("user-token");
            localStorage.removeItem("user-fio");
            localStorage.removeItem("user-mail");
            localStorage.removeItem("user-phone");
            localStorage.removeItem("used-id");
            commit("updateDiler", false);
            localStorage.removeItem("isDiler");
            commit("updateDirections", null);
            if (router.path !== "login") router.push({ name: "Login" });
            resolve();
        });
    }
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = "success";
        state.token = resp.token;
        state.hasLoadedOnce = true;
    },
    [AUTH_ERROR]: state => {
        state.status = "error";
        state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT]: state => {
        state.token = "";
    },
    updateDiler(state, bool) {
        state.isDiler = bool;
    },
    updateDirections(state, arr) {
        state.user_directions = arr;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};