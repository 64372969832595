<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="list-requests">
    <div class="list-requests-container" v-if="requests !== null">
      <el-row type="flex" class="list-requests__search">
        <el-input v-model="search" size="mini" placeholder="Поиск" />
        <el-button size="mini" type="primary" icon="el-icon-search"></el-button>
      </el-row>
      <el-table class="list-requests__table" :row-class-name="classForRow" :data="
        requests.filter(
          (data) =>
            data['nameCompany'] !== null &&
            (!search ||
              data['nameCompany']
                .toLowerCase()
                .includes(search.toLowerCase()))
        )
      " :default-sort="{ prop: 'date', order: 'descending' }" stripe style="width: 100%">
        <el-table-column prop="date" width="150" sortable :sort-method="sortDate" label="Дата">
        </el-table-column>
        <el-table-column label="Компания" min-width="130" prop="nameCompany">
        </el-table-column>
        <el-table-column label="Статус" min-width="95" prop="status">
        </el-table-column>
        <el-table-column label="Комментарий" min-width="200" prop="comment">
        </el-table-column>
        <el-table-column label="ИНН" min-width="200" prop="inn">
        </el-table-column>
        <el-table-column label="КПП" min-width="200" prop="kpp">
        </el-table-column>
        <el-table-column label="Причина закрепления" min-width="200" prop="reason">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import moment from "moment";
import { ADRESS } from "@/store/actions/auth";
import Fetch from "./mixins/Fetch";
import { handelerErrors } from "@/plugins/utils"
export default {
  name: "CompanyRequests",
  mixins: [Fetch],
  data() {
    return {
      requests: null,
      search: "",
      fullscreenLoading: false,
      activities: null,
      userId: "",
      isDialogShow: false,
      currentCall: null,
      lists: null,
    };
  },
  methods: {
    fetchData() {
      console.log("fetch");
      this.activities = localStorage.getItem("user-token");
      this.getListRequests();
    },
    sortDate(a, b) {
      return (
        moment(a.date, "DD.MM.YYYY HH:mm:ss").unix() -
        moment(b.date, "DD.MM.YYYY HH:mm:ss").unix()
      );
    },
    getListRequests() {
      this.fullscreenLoading = true;
      axios
        .get(`${ADRESS}company-requests`, {
          headers: { Authorization: this.activities },
        })
        .then((response) => {
          this.requests = response.data.requests;
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    onClickInCall(row) {
      console.log(row);
      this.currentCall = row;
    },
    classForRow(row) {
      switch (row.row["Статус"]) {
        case "Просрочено":
          return "requests-row__expired";
        default:
          return "requests-row__normal";
      }
    },
    removeCall(uik) {
      this.requests = this.requests.filter((item) => item["УИК"] !== uik);
    },
  },
};
</script>

<style lang="scss">
.list-requests-container {
  margin-top: 15px;
}

.list-requests {
  .list-requests__table {
    color: #000000;
    font-size: 12px;

    tr {
      span {
        overflow: hidden;
        word-spacing: nowrap;
      }
    }

    tr.requests-row__expired {
      background-color: #f58e8e;

      td {
        background-color: #f58e8e;
      }
    }
  }
}
</style>
