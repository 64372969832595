<template>
  <div class="AllClients" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="AllClients-container" v-if="clients !== null">
      <div class="AllClients-search">
        <el-input class="AllClients-search__input" v-model="search" size="mini" placeholder="Введите название или ИНН" />
        <vClientLimit :fixed="fixed" :inProcess="inProcess" :limit="limit" />
      </div>
      <el-table :data="clients.filter(
        (data) =>
          !search ||
          data['ИНН'].toLowerCase().includes(search.toLowerCase()) ||
          data['Наименование'].toLowerCase().includes(search.toLowerCase())
      )
        " :default-sort="{ prop: 'Наименование', order: 'ascending' }" stripe @row-click="handleCurrentChange"
        style="width: 100%">
        <el-table-column prop="Наименование" sortable label="Наименование">
        </el-table-column>
        <el-table-column prop="Регион" sortable width="190" label="Регион">
        </el-table-column>
        <el-table-column v-if="$mq.resize && $mq.above('600px')" width="150" prop="ИНН" label="ИНН">
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-if="dialogVisibleOuter" :title="company['НаименованиеПолное']" class="dialog-company"
      :visible.sync="dialogVisibleOuter" @close="closeCompany" width="90%">
      <el-tabs v-model="activeTab" @tab-click="changeTab">
        <!-- <el-tabs v-model="activeTab"> -->
        <el-tab-pane label="Основное" name="1">
          <el-row class="dialog-company-req">
            <span>ИНН: <strong>{{ company["ИНН"] }}</strong></span>
            <span>КПП: <strong>{{ company["КПП"] }}</strong></span>
          </el-row>
          <el-row>
            <span>Категория клиента: {{ company["КатегорияКлиента"] }}</span>
          </el-row>
          <el-row>
            <span>Источник регистрации: {{ company["ИсточнкРегистрации"] }}</span>
          </el-row>
          <el-row>
            <span>Закрепление:</span>
            <el-table :data="company['Закрепление']" style="width: 100%">
              <el-table-column prop="Период" label="Период"> </el-table-column>
              <el-table-column prop="Направление" label="Направление">
              </el-table-column>
              <el-table-column prop="Пользователь" label="Пользователь">
              </el-table-column>
            </el-table>
          </el-row>
          <div v-for="(item, index) in companyTechPark" :key="index" class="AllClients__TechPark">
            <template v-if="!isDiler || list_dirs.includes(item.name) || user_directions.length > 1">
              <h3>{{ item.name }}</h3>
              <el-row>
                <span>Количество единиц техники: </span>
                <el-input-number size="mini" :min="0" v-model="item.count"></el-input-number>
              </el-row>
              <el-row>
                <span>Парк техники:</span>
                <el-input type="textarea" autosize placeholder="" v-model="item.desc">
                </el-input>
              </el-row>
            </template>
          </div>
          <el-row>
            <el-button @click="saveParkTech">Сохранить</el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Взаимодействия" name="2">
          <div class="relation-create">
            <div class="relation-create__header">
              <el-button size="small" type="primary" @click="createRelationCall">Создать звонок</el-button>
              <el-button size="small" type="success" @click="createRelationMeet">Создать встречу</el-button>
            </div>
            <el-dialog width="90%" :title="titleDialogRelation" :visible.sync="isCreatingRelation" append-to-body>
              <vRelation :clientsContact="clientsContact" :activities="activities" :isCreatingMeet="isCreatingMeet"
                :uikCompany="uik" :isEditableRelation="isEditableRelation" :currentRelation="currentRelation"
                @addNewRelation="addNewRelation" @closeDialog="isCreatingRelation = false" />
            </el-dialog>
            <div class="client-relations">
              <div v-for="(relationsObj, index) in relations" :key="index" class="client-relations__item">
                <div class="date-label">
                  <div class="date-label__text">{{ relationsObj.month }}</div>
                </div>
                <div v-for="(relation, index) in relationsObj.data" :key="index" class="note"
                  @click="onClickRelation(relation)">
                  <div class="note-left">
                    <div class="note-header">
                      <span class="note-header__date">{{
                        relation.Создан
                      }}</span>
                      <span> с </span>
                      <span class="note-header__member">{{
                        relation.Участники | subStrNames
                      }}</span>
                    </div>
                    <div v-if="relation.Тема" class="note-title">
                      {{ relation.Тема }}
                    </div>
                    <div v-if="relation.Направление" class="note-title">
                      Направление: {{ relation.Направление }}
                    </div>
                    <div v-if="relation.МодельТехники" class="note-title">
                      {{ relation.МодельТехники }}
                    </div>
                    <div v-if="relation.СрокПоставки" class="note-title">
                      Срок поставки: {{ relation.СрокПоставки }}
                    </div>
                    <div v-if="relation.Сумма" class="note-title">
                      Сумма: {{ relation.Сумма }}
                    </div>
                    <div class="note-description">{{ relation.Описание }}</div>
                    <div class="note-description">
                      <ul>
                        <li v-for="(item, index) in relation['Комментарий']" :key="index">
                          <span>{{ item.date }}: </span>
                          <strong>{{ item.text }}</strong>
                        </li>
                      </ul>
                    </div>
                    <div class="note-description">
                      <ul>
                        <li v-for="(item, index) in relation.Техника" :key="index">
                          {{ relation.Техника }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="note-right">
                    <span class="note-header__type">{{
                      relation.Тип | reNameTitleNote(relation.Авто)
                    }}</span>
                    <span class="note-header__author">{{
                      relation.Автор
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Контактные лица" name="3">
          <el-collapse class="all-clients__contact-list" v-model="contactsActiveCollapse" :accordion="true"
            @change="setCurrentCollapse">
            <el-collapse-item v-for="(value, index) in contactsList" class="all-clients__contact-item" :key="index"
              :name="index" :disabled="value['Недействителен'] != 'Нет'">
              <template slot="title">
                <strong>{{ value["ФИО"] }} - {{ value["Должность"] }}</strong>
              </template>
              <div class="contacts-phones">
                <span>Телефон:</span>
                <div v-for="(value, index) in currentPhone" :key="index">
                  <el-input v-model="currentPhone[index]" placeholder="Введите номер телефона"></el-input>
                </div>
                <el-button type="primary" icon="el-icon-plus" circle @click="addPhoneInContacts"
                  :disabled="!disablePhoneButton"></el-button>
              </div>
              <div class="contacts-mails">
                <span>Почта:</span>
                <div v-for="(value, index) in currentEmail" :key="index">
                  <el-input v-model="currentEmail[index]" placeholder="Введите эл. почту"></el-input>
                </div>
                <el-button type="primary" icon="el-icon-plus" circle @click="addMailInContacts"
                  :disabled="!disableEmailButton"></el-button>
              </div>
              <el-popconfirm confirm-button-text="Да" cancel-button-text="Нет" icon="el-icon-info" icon-color="red"
                title="Удалить данный контакт?" @confirm="removeCurrentContact">
                <el-button class="remove-contact-button" type="danger" slot="reference">Удалить</el-button>
              </el-popconfirm>
              <el-button @click="saveContacts">Сохранить</el-button>
            </el-collapse-item>
          </el-collapse>
          <el-button class="add-new-contact-button" type="primary" @click="isAddFormOpen = !isAddFormOpen">Добавить
            нового</el-button>
          <div v-if="isAddFormOpen" class="add-new-contact">
            <div>
              <span>ФИО: </span>
              <el-input v-model="newContactName" />
            </div>
            <div>
              <span>Должность: </span>
              <el-input v-model="newContactPost" />
            </div>
            <div class="contacts-phones">
              <span>Телефон: </span>
              <div v-for="(value, index) in newContactPhone" :key="index">
                <el-input v-model="newContactPhone[index]" placeholder="Введите номер телефона"></el-input>
              </div>
              <el-button type="primary" icon="el-icon-plus" circle @click="addPhoneInNewContact"
                :disabled="!disableAddPhoneButtonInNewContact"></el-button>
            </div>
            <div class="contacts-mails">
              <span>Почта: </span>
              <div v-for="(value, index) in newContactEmail" :key="index">
                <el-input v-model="newContactEmail[index]" placeholder="Введите эл. почту"></el-input>
              </div>
              <el-button type="primary" icon="el-icon-plus" circle @click="addMailInNewContact"
                :disabled="!disableAddEmailButtonInNewContact"></el-button>
            </div>
            <el-button v-if="validateNewContact" @click="addNewConctact">Сохранить</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-dialog width="80%" title="Укажите причину открепления предприятия" :visible.sync="dialogVisibleInner"
        append-to-body>
        <el-input v-model="detachCause" />
        <div slot="footer">
          <el-button @click="detachCompany" type="danger">Открпепить</el-button>
          <el-button @click="closeDialogVisibleInner">Закрыть</el-button>
        </div>
      </el-dialog>
      <span v-if="activeTab == 1" slot="footer" class="dialog-footer">
        <el-button type="danger" @click="dialogVisibleInner = true">Открепить</el-button>
        <el-button @click="closeCompany">Закрыть</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";
import vClientLimit from "./components/vClientLimit.vue";
import vRelation from "./components/vRelation.vue";
import { ADRESS } from "@/store/actions/auth";
import Fetch from "./mixins/Fetch";
import { handelerErrors } from "@/plugins/utils"

export default {
  name: "AllClients",
  components: {
    vClientLimit,
    vRelation,
  },
  mixins: [Fetch],
  data() {
    return {
      activities: "",
      search: "",
      fullscreenLoading: false,
      clients: null,
      dialogVisibleOuter: false,
      dialogVisibleInner: false,
      company: null,
      activeTab: "1",
      companyTechPark: [],
      uik: "",
      detachCause: "",
      contactsActiveCollapse: ["0"],
      contactsList: null,
      currentEmail: null,
      currentPhone: null,
      isAddFormOpen: false,
      newContactName: "",
      newContactPost: "",
      newContactPhone: [""],
      newContactEmail: [""],
      fixed: 0,
      inProcess: 0,
      limit: 0,
      relations: [],
      clientsContact: [],
      isCreatingRelation: false,
      isCreatingMeet: null,
      currentRelation: null,
      titleDialogRelation: "",
      isEditableRelation: false,
      list_dirs: [],
    };
  },
  computed: {
    ...mapGetters(["isDiler", "user_directions"]),
    disablePhoneButton() {
      if (this.currentPhone !== null) {
        if (this.currentPhone.length == 0) {
          return true;
        } else {
          return this.currentPhone.every((elem) => elem.length > 0);
        }
      } else {
        return false;
      }
    },
    disableEmailButton() {
      if (this.currentEmail !== null) {
        if (this.currentEmail.length == 0) {
          return true;
        } else {
          return this.currentEmail.every((elem) => elem.length > 0);
        }
      } else {
        return false;
      }
    },
    disableAddPhoneButtonInNewContact() {
      if (this.newContactPhone !== null) {
        if (this.newContactPhone.length == 0) {
          return true;
        } else {
          return this.newContactPhone.every((elem) => elem.length > 0);
        }
      } else {
        return false;
      }
    },
    disableAddEmailButtonInNewContact() {
      if (this.newContactEmail !== null) {
        if (this.newContactEmail.length == 0) {
          return true;
        } else {
          return this.newContactEmail.every((elem) => elem.length > 0);
        }
      } else {
        return false;
      }
    },
    validateNewContact() {
      return (
        this.newContactName.length > 0 &&
        this.newContactPost.length > 0 &&
        this.newContactPhone[0].length > 0 &&
        this.newContactEmail[0].length > 0
      );
    },
  },
  watch: {
    isCreatingRelation(value) {
      this.$store.dispatch("changeIsCreatingRelation", value);
      if (value === false) {
        this.isCreatingMeet = null;
        this.isEditableRelation = false;
      }
    },
  },
  filters: {
    reNameTitleNote(value, auto) {
      switch (value) {
        case "Контрагенты":
          return "Сделка";
        case "Телефонный звонок":
          return auto == true ? "Телефонный звонок Манго" : "Телефонный звонок";
        case "(TR) Телефонный звонок":
          return "Телефонный звонок МТС";
        default:
          return value;
      }
    },
    subStrNames(value) {
      return value.length > 26 ? value.substring(0, 25) + "..." : value;
    },
  },
  methods: {
    fetchData() {
      this.activities = localStorage.getItem("user-token");
      if (this.isDiler && this.user_directions !== null && this.user_directions.length == 1) {
        if (this.user_directions[0] == "000000001") {
          this.list_dirs = [
            "ВТ с ДВС",
            "ВТ с эл. двиг.",
          ]
        }
        if (this.user_directions[0] == "000000003") {
          this.list_dirs = [
            "ДСТ",
            "Экскаваторы",
            "Фронтальные погрузчики",
            "Мини погрузчики",
          ]
        }
      }
      this.getListFirm();
      moment.locale("ru");
    },
    getListFirm() {
      this.fullscreenLoading = true;

      axios({
        method: "get",
        url: ADRESS + "getlistfirm",
        headers: { Authorization: this.activities },
      })
        .then((response) => {
          this.clients = response.data.firms;
          this.fixed = parseInt(response.data.fixed);
          this.inProcess = parseInt(response.data.inProcess);
          this.limit = parseInt(response.data.limit);
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    getSearch() { },
    handleCurrentChange(val) {
      this.fullscreenLoading = true;
      this.uik = val["УИК"];
      axios({
        method: "get",
        url: ADRESS + "firmview",
        params: {
          uik: val["УИК"],
        },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.company = response.data;
          this.companyTechPark = response.data["ПаркТехники"];
          this.contactsList = response.data["КонтактныеЛица"];
          this.clientsContact = response.data["КонтактныеЛица"].map((item) => {
            return {
              name: item["ФИО"],
              uik: item["УИК"],
            };
          });
          this.dialogVisibleOuter = true;
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    saveParkTech() {
      this.fullscreenLoading = true;
      axios({
        method: "post",
        url: ADRESS + "firmedit",
        data: {
          uik: this.uik,
          companyTechPark: this.companyTechPark,
        },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.$notify.success({
            title: "Сохранено",
            message: response.data,
          });
          this.company["ПаркТехники"] = this.companyTechPark;
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    detachCompany() {
      if (this.detachCause.length > 0) {
        this.fullscreenLoading = true;
        axios({
          method: "post",
          url: ADRESS + "firm-remove",
          data: {
            uik: this.uik,
            detachCause: this.detachCause,
          },
          headers: {
            Authorization: this.activities,
            "X-CSRFToken": window.csrf_token,
          },
        })
          .then((response) => {
            this.$notify.success({
              title: "Сохранено",
              message: response.data,
            });
            this.dialogVisibleInner = false;
            this.dialogVisibleOuter = false;
            this.detachCause = "";
            this.fullscreenLoading = false;
          })
          .catch((err) => {
            this.fullscreenLoading = false;
            handelerErrors(err);
          });
      } else {
        this.$notify.error({
          title: "Ошибка",
          message: "Причина обязательна для заполнения",
        });
      }
    },
    closeDialogVisibleInner() {
      this.detachCause = "";
      this.dialogVisibleInner = false;
    },
    setCurrentCollapse(val) {
      if (this.contactsActiveCollapse !== "") {
        this.currentEmail = this.contactsList[val]["Почта"];
        this.currentPhone = this.contactsList[val]["Телефон"];
        this.currentContactUik = this.contactsList[val]["УИК"];
      }
    },
    addPhoneInContacts() {
      this.currentPhone.push("");
    },
    addMailInContacts() {
      this.currentEmail.push("");
    },
    closeCompany() {
      this.contactsActiveCollapse = "";
      this.activeTab = "1";
      this.currentPhone = null;
      this.currentEmail = null;
      this.dialogVisibleOuter = false;
    },
    saveContacts() {
      this.fullscreenLoading = true;

      axios({
        method: "post",
        url: ADRESS + "firm-update-contact",
        data: {
          uikCompany: this.uik,
          uikContact: this.currentContactUik,
          phone: this.currentPhone,
          email: this.currentEmail,
        },
        headers: {
          Authorization: this.activities,
          "X-CSRFToken": window.csrf_token,
        },
      })
        .then((response) => {
          this.$notify.success({
            title: "Сохранено",
            message: response.data,
          });
          this.contactsActiveCollapse = "";
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    addPhoneInNewContact() {
      this.newContactPhone.push("");
    },
    addMailInNewContact() {
      this.newContactEmail.push("");
    },
    addNewConctact() {
      if (this.validateEmail(this.newContactEmail)) {
        this.fullscreenLoading = true;

        axios({
          method: "post",
          url: ADRESS + "firm-add-contact",
          data: {
            newContactName: this.newContactName,
            newContactPost: this.newContactPost,
            newContactPhone: this.newContactPhone,
            newContactEmail: this.newContactEmail,
            uikCompany: this.uik,
          },
          headers: {
            Authorization: this.activities,
          },
        })
          .then((response) => {
            this.$notify.success({
              title: "Сохранено",
              message: "",
            });
            const curUik = response.data.uik;
            this.contactsList.push({
              Должность: this.newContactPost,
              Недействителен: "Нет",
              Почта: this.newContactEmail,
              Телефон: this.newContactPhone,
              УИК: curUik ? curUik : "1",
              ФИО: this.newContactName,
            });
            this.newContactName = "";
            this.newContactPost = "";
            this.newContactPhone = [""];
            this.newContactEmail = [""];
            this.isAddFormOpen = false;
            this.fullscreenLoading = false;
          })
          .catch((err) => {
            this.fullscreenLoading = false;
            handelerErrors(err)
          });
      } else {
        this.$notify.error({
          title: "Ошибка",
          message: "Не правильная почта",
        });
      }
    },
    removeCurrentContact() {
      this.fullscreenLoading = true;

      axios({
        method: "post",
        url: ADRESS + "firm-remove-contact",
        data: {
          uikContact: this.currentContactUik,
        },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.$notify.success({
            title: "Успешно удалено",
            message: "response.data",
          });
          this.contactsList[this.contactsActiveCollapse]["Недействителен"] =
            "Да";
          this.contactsActiveCollapse = "";
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    changeTab() {
      if (this.activeTab == "2") {
        this.getFirmRelations();
      }
    },
    createRelationCall() {
      this.titleDialogRelation = "Создание телефонного звонка";
      this.isCreatingRelation = true;
      this.isCreatingMeet = false;
    },
    createRelationMeet() {
      this.titleDialogRelation = "Создание встречи";
      this.isCreatingRelation = true;
      this.isCreatingMeet = true;
    },
    getFirmRelations() {
      this.fullscreenLoading = true;
      axios
        .get(ADRESS + "firmrelations?uik=" + this.uik, {
          headers: { authorization: this.activities },
        })
        .then((response) => {
          this.relations = response.data.relations;
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    addNewRelation(relation) {
      this.isCreatingRelation = false;
      this.getFirmRelations();
    },
    onClickRelation(relation) {
      this.isEditableRelation = false;
      this.titleDialogRelation = this.reNameTitleNote(
        relation["Тип"],
        relation["Авто"]
      );
      if (
        relation["Тип"] === "Телефонный звонок" ||
        relation["Тип"] === "(TR) Телефонный звонок"
      ) {
        this.isEditableRelation = true;
        this.isCreatingMeet = false;
      } else if (relation["Тип"] === "Запланированное взаимодействие") {
        this.isEditableRelation = true;
        this.isCreatingMeet = null;
      } else if (relation["Тип"] === "Встреча") {
        this.isEditableRelation = true;
        this.isCreatingMeet = true;
      }
      if (this.isEditableRelation === true) {
        this.currentRelation = relation;
        this.isCreatingRelation = true;
      }
    },
    reNameTitleNote(value, auto) {
      switch (value) {
        case "Контрагенты":
          return "Сделка";
        case "Телефонный звонок":
          return auto == true ? "Телефонный звонок Манго" : "Телефонный звонок";
        case "(TR) Телефонный звонок":
          return "Телефонный звонок МТС";
        default:
          return value;
      }
    },
  },
};
</script>

<style lang="scss">
.AllClients-container {
  .AllClients-search {
    margin: 15px 0 10px;
    padding: 0 15px;
    display: flex;
  }

  .AllClients-search__input {
    max-width: 100%;
  }

  @media (max-width: 650px) {
    .el-table {
      font-size: 12px;
    }
  }

  .el-table {
    cursor: pointer;

    .cell {
      line-height: 18px;
    }
  }

  .AllClients-search {
    padding: 0 10px;
  }
}

.AllClients__TechPark {
  margin-top: 20px;
}

.dialog-company {
  .el-dialog__body {
    padding: 10px 20px 30px;
  }

  .dialog-company-req {
    color: #000;
    display: flex;
    flex-wrap: wrap;

    span:first-child {
      margin-right: 20px;
    }

    span>strong {
      display: inline;
    }
  }

  .el-row {
    margin: 7px 0;

    >span {
      line-height: 25px;
    }

    strong {
      display: block;
    }
  }

  .el-input-number {
    display: block;
  }

  .contacts-phones,
  .contacts-mails {
    margin-bottom: 20px;

    .el-input {
      margin-bottom: 15px;
    }
  }

  .el-collapse-item__header {
    height: auto;
    line-height: 24px;
    padding: 10px 0;
  }

  .contacts-mails {
    .el-button {
      display: block;
    }
  }

  .add-new-contact-button {
    margin: 15px 0;
  }

  .remove-contact-button {
    margin-right: 20px;
  }

  .add-new-contact div span {
    display: block;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  @media (max-width: 650px) {
    .el-table .cell {
      padding: 0 5px !important;
      line-height: 16px;
      font-size: 10px;
    }

    .el-table td,
    .el-table th {
      padding: 8px 0;
    }

    .el-dialog__header {
      padding: 20px 20px 0;

      .el-dialog__title {
        line-height: 18px;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .dialog-footer .el-button {
      margin: 15px;
    }
  }
}

.client-relations {
  .content {
    margin-top: 100px;
    margin-bottom: 100px;
    display: inline-block;
    width: 90%;
    min-height: 1000px;
    background-color: #f5f5f5;
    border: 1px solid #000;
    padding: 0 20px;
  }

  .date-label {
    text-align: center;
    position: relative;
    display: block;
    margin: 15px 0;
  }

  .date-label:before {
    content: "";
    position: absolute;
    left: -20px;
    right: -20px;
    top: 10px;
    height: 1px;
    background: #e3e3e3;
  }

  .date-label__text {
    color: #6b6d72;
    display: inline-block;
    font-size: 13px;
    position: relative;
    z-index: 0;
    padding: 2px 0;
  }

  .date-label__text:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -12px;
    right: -12px;
    background: #fff;
    border: 1px solid #e3e3e3;
    z-index: -1;
    border-radius: 10px;
  }

  .note {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    border: 1px solid #c3c3c3;
    border-radius: 3px;
    padding: 12px 12px 15px 12px;
    box-sizing: border-box;
    background-color: #ffffff;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    position: relative;

    @media (max-width: 550px) {
      font-size: 12px;
      line-height: 15px;
      flex-direction: column;

      .note-right {
        margin-left: 0;
        margin-top: 7px;
        padding-top: 7px;
        border-top: 1px solid #777;
      }
    }

    .note-description {
      margin-left: 15px;

      li {
        margin-left: 20px;
      }

      span {
        font-size: 11px;
      }

      strong {
        color: #000;
      }
    }
  }

  .note.red {
    background-color: #ee9086;
  }

  .note-right {
    min-width: 150px;
    margin-left: 15px;

    span {
      display: block;
      text-align: right;
    }
  }

  .note-header {
    position: relative;
    margin-bottom: 7px;
  }

  .note-header__date {
    color: #555555;
    font-size: 12px;

    @media (max-width: 550px) {
      font-size: 11px;
    }
  }

  .note-header__member {
    color: #000000;
    font-weight: bold;
  }

  .note-header__type {
    color: #000;
    font-weight: bold;
    margin-bottom: 3px;
  }

  .note-header__right span {
    display: block;
    text-align: right;
  }

  .note-title {
    color: #000;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 3px;
    font-size: 16px;

    @media (max-width: 550px) {
      font-size: 14px;
    }
  }

  .icon-container {
    width: 36px;
    height: 36px;
    border: 1px solid #777777;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 22px;
    height: 22px;
    background-size: contain;
    background-position: center;
    display: flex;
  }
}

.relation-create__form {
  min-height: 300px;
}

.relation-create__row,
.task-create__row {
  margin: 10px 0;
  display: block;

  p {
    color: #000000;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .el-date-editor--datetime {
    @media (max-width: 550px) {
      margin-bottom: 10px;
    }
  }
}

.relation-create__row-comment .el-input,
.task-create__row-comment .el-input {
  width: calc(100% - 50px);
  margin-right: 10px;
}

.relation-create__form,
.task-create {
  .el-range-editor.el-input__inner {
    width: 100%;
    max-width: 420px;
  }

  .el-date-editor .el-range-separator {
    width: 28px;
  }
}

.all-clients__contact-item strong {
  font-size: 11px;
  line-height: 17px;
}
</style>