import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import auth from "./modules/auth";
import relation from "./modules/relation";
import Inputmask from 'inputmask'
import VueTheMask from 'vue-the-mask'

Vue.use(Vuex);
Vue.use(VueTheMask)

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        user,
        auth,
        relation
    },
    strict: debug
});