export default {
    state: {
        isCreatingRelation: false
    },
    actions: {
        changeIsCreatingRelation(ctx, value) {
            ctx.commit('updateIsCreatingRelation', value)
        }
    },
    mutations: {
        updateIsCreatingRelation(state, value) {
            state.isCreatingRelation = value;
        }
    },
    getters: {
        isCreatingRelation: state => state.isCreatingRelation
    }
}