<template>
  <div class="checkFirm" v-loading.fullscreen.lock="fullscreenLoading">
    <el-container direction="vertical">
      <el-form @submit.native.prevent="search" label-width="70px">
        <div class="checkFirm__limits">
          <div class="clients-limit__container">
            <span>Закреплено:</span>
            <vClientLimit :fixed="fixed" :inProcess="inProcess" :limit="limit" />
          </div>
          <el-statistic title="Доступных проверок:">
            <template slot="formatter">
              {{ AvailableNumberRequests }}
            </template>
          </el-statistic>
        </div>
        <el-form-item class="switch-legal" label="Статус:">
          <el-switch style="display: block" v-model="company.isLegal" active-color="#13ce66" inactive-color="#ff4949"
            active-text="Юридическое лицо" inactive-text="Физическое лицо" @change="searchKPP = ''">
          </el-switch>
        </el-form-item>

        <el-form-item label="ИНН:" :maxlength="15">
          <el-input v-model="searchINN" type="number" />

          <div class="input__dropdown-info" v-if="isSearchINNLengthMax">
            Пожалуйста смените флажок на "Физическое лицо", количество символов ИНН не соответствует юридическому лицу. 
          </div>
        </el-form-item>

        <el-form-item label="КПП:" v-if="company.isLegal && !isDiler">
          <el-input v-model="searchKPP" :maxlength="15" type="number" />
        </el-form-item>
        <el-row type="flex" justify="space-around" class="checkFirm-buttons">
          <el-button :disabled="disableButton" :loading="loading" size="small" type="primary" native-type="submit"
            icon="el-icon-search">Поиск</el-button>
          <el-button :disabled="!disableButtonCreate" size="small" type="success" @click="createCompany">Создать нового
            контрагента</el-button>
        </el-row>
      </el-form>
      <div v-if="companiesData.length > 0" class="companies">
        <div v-for="(company, index) in companiesData" :key="index" class="company">
          <h2>{{ company["НаименованиеПолное"] }}</h2>
          <div class="company-req">
            <span>ИНН: <strong>{{ company["ИНН"] }}</strong>,
            </span>
            <span>КПП: <strong>{{ company["КПП"] }}</strong></span>
          </div>
          <span class="company-city">Город: <strong>{{ company["Город"] }}</strong></span>
          <div class="company-info">
            <h3>Закрепление</h3>
            <ul>
              <li v-for="(item, index) in company['Закрепление']" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="company-info">
            <h3>Взаимоотношения</h3>
            <ul>
              <li v-for="(item, index) in company['Взаимоотношения']" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="company-info">
            <h3>Сделки</h3>
            <ul>
              <li v-for="(item, index) in company['Сделки']" :key="index">
                {{ item }}
              </li>
            </ul>
            <el-button class="button-re-create" type="warning" plain @click="openDialogForReCreate(index)">Отправить на
              перезакрепление</el-button>
          </div>
        </div>
      </div>
    </el-container>
    <el-dialog title="Запрос на закрепление предприятия" class="dialog-fix-company" :visible.sync="dialogVisible"
      @closed="cleanCompany" width="80%">
      <div v-if="optionsCompany !== null" class="form-fix-company">
        <div class="form-fix-company__header">
          <el-input size="small" v-model="company.fullName" />
        </div>
        <el-switch style="display: block; margin-bottom: 10px" v-model="company.isLegal" :disabled="true"
          active-color="#13ce66" inactive-color="#ff4949" active-text="Юридическое лицо" inactive-text="Физическое лицо">
        </el-switch>
        <div class="form-fix-company__limits">
          <span>Закреплено:</span>
          <vClientLimit :fixed="optionsCompany.fixed" :inProcess="optionsCompany.inProcess"
            :limit="optionsCompany.limit" />
        </div>
        <div class="form-fix-company__requisites">
          <span>ИНН: <strong>{{ company.inn }}</strong>
          </span>
          <span v-if="company.kpp.length > 3">, КПП: <strong>{{ company.kpp }}</strong>
          </span>
        </div>
        <el-input v-if="company.isLegal && isDiler" v-model="company.kpp" :maxlength="15" type="number" placeholder="КПП"
          :class="company.kpp.length !== 9
            ? 'red-border form-fix-company__input'
            : 'form-fix-company__input'
            " />
        <v-select v-model="company.customerCategory" label="name"
          :class="company.customerCategory === null ? 'red-border' : ''" :options="optionsCompany.customerCategories"
          placeholder="Категория клиента"></v-select>
        <v-select v-if="firms.length > 0 &&
          company.customerCategory !== null &&
          company.customerCategory.name === 'Работа через дилера'
          " v-model="company.dealer" :class="company.dealer === null ? 'red-border' : ''" label="firm" :options="firms"
          placeholder="Дилер"></v-select>
        <v-select v-model="company.formOwnership"
          :class="company.formOwnership === null || company.formOwnership === undefined ? 'red-border' : ''" label="name"
          :options="optionsCompany.formsOwnership" placeholder="Форма собственности"></v-select>
        <v-select v-model="company.source" :class="company.source === null ? 'red-border' : ''" label="name"
          :options="optionsCompany.sources" placeholder="Источник регистрации"></v-select>
        <el-input :class="company.country.length < 4
          ? 'red-border form-fix-company__input'
          : 'form-fix-company__input'
          " size="small" placeholder="Страна" v-model="company.country" />
        <el-input :class="company.region.length < 3
          ? 'red-border form-fix-company__input'
          : 'form-fix-company__input'
          " size="small" placeholder="Регион" v-model="company.region" />
        <el-input :class="company.city.length < 3
          ? 'red-border form-fix-company__input'
          : 'form-fix-company__input'
          " size="small" placeholder="Город" v-model="company.city" />
        <el-checkbox-group v-if="!isDiler || (isDiler && user_directions !== null && user_directions.length > 1)"
          v-model="company.directions" class="form-fix-company__directions">
          <el-checkbox v-for="(direction, index) in optionsCompany.directions" :label="direction.id" :key="index"
            class="form-fix-company__direction">{{ direction.name }}</el-checkbox>
        </el-checkbox-group>
        <div v-if="!isDiler" class="form-fix-company__switch">
          <span>Закрепить на заводе TCM</span>
          <el-switch v-model="company.isFactoryTcm" />
        </div>
        <div v-if="!isDiler" class="form-fix-company__switch">
          <span>Закрепить на заводе Noblelift</span>
          <el-switch v-model="company.isFactoryNoblelift" />
        </div>
        <div class="form-fix-company__textarea">
          <el-input type="textarea" :class="company.cause.length < 6 ? 'red-border' : ''" size="small" autosize
            placeholder="Причина закрепления" v-model="company.cause" />
        </div>
        <div class="form-fix-company__contact">
          <div>
            <h3>Контактная информация:</h3>
            <div>
              <el-input size="small" :class="company.contact.name.length < 5 ? 'red-border' : ''"
                placeholder="Введите ФИО" v-model="company.contact.name" />
            </div>
          </div>
          <div>
            <div>
              <el-input size="small" :class="company.contact.post.length < 5 ? 'red-border' : ''"
                placeholder="Введите должность" v-model="company.contact.post" />
            </div>
          </div>
          <div class="form-fix-company__phones">
            <h4>Телефоны:</h4>
            <div v-for="(value, index) in company.contact.phones" :key="index">
              <el-input v-model="company.contact.phones[index]" :class="company.contact.phones[index].length < 7 ? 'red-border' : ''
                " size="small" placeholder="Введите номер телефона"></el-input>
            </div>
            <el-button type="primary" icon="el-icon-plus" size="small" @click="addPhoneInNewContact"
              :disabled="!disableAddPhoneButtonInNewContact">Добавить телефон</el-button>
          </div>
          <div class="form-fix-company__mails">
            <h4>Почты:</h4>
            <div v-for="(value, index) in company.contact.emails" :key="index">
              <el-input v-model="company.contact.emails[index]" size="small" placeholder="Введите эл. почту"></el-input>
            </div>
            <el-button type="primary" icon="el-icon-plus" size="small" @click="addMailInNewContact"
              :disabled="!disableAddEmailButtonInNewContact">Добавить почту</el-button>
          </div>
        </div>
        <h3>Парк техники:</h3>
        <div v-for="(item, index) in company.parkTechs" :key="index" class="form-fix-company__tech-park">
          <template v-if="!isDiler || list_dirs.includes(item.name) || user_directions.length > 1">
            <h4>{{ item.name }}</h4>
            <el-row>
              <span>Количество единиц техники: </span>
              <el-input-number size="mini" :min="0" v-model="item.count"></el-input-number>
            </el-row>
            <el-row>
              <span>Описание:</span>
              <el-input type="textarea" autosize placeholder="" v-model="item.desc">
              </el-input>
            </el-row>
          </template>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="sendForFixing" :disabled="isValidFormData">Отправить</el-button>
        <el-button @click="dialogVisible = false">Закрыть</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import axios from "axios";
import vClientLimit from "./components/vClientLimit.vue";
import { ADRESS } from "@/store/actions/auth";
import Fetch from "./mixins/Fetch";
import { mapGetters } from "vuex";
import { handelerErrors } from "@/plugins/utils"

export default {
  name: "CheckFirm",
  components: {
    vClientLimit,
  },
  mixins: [Fetch],
  data() {
    return {
      isSearchINNLengthMax: false,
      activities: "",
      fullscreenLoading: "",
      searchINN: "",
      searchKPP: "",
      loading: false,
      categoryClient: "",
      companiesData: [],
      isNewCompany: false,
      isCreatingCompany: false,
      company: {
        shortName: "",
        fullName: "",
        isLegal: true,
        inn: "",
        kpp: "",
        uik: "",
        formOwnership: null,
        customerCategory: null,
        source: null,
        country: "",
        city: "",
        region: "",
        parkTechs: "",
        isFactoryTcm: false,
        isFactoryNoblelift: false,
        directions: [],
        contact: {
          name: "",
          post: "",
          phones: [""],
          emails: [""],
        },
        cause: "",
        dealer: null,
      },
      firms: [],
      dialogVisible: false,
      optionsCompany: null,
      checkedCompany: [],
      daDataObj: null,
      cleanData: null,
      cleanCompanyData: null,
      fixed: 0,
      limit: 100,
      AvailableNumberRequests: 0,
      inProcess: 0,
      list_dirs: [],
    };
  },
  computed: {
    ...mapGetters(["isDiler", "user_directions"]),
    disableButton() {
      let str = this.searchINN + "";
      return str.length < 7;
    },
    disableButtonCreate() {
      let searchINN = this.searchINN + "";
      let searchKPP = this.searchKPP + "";
      return (
        searchINN.length > 7 &&
        (searchKPP.length > 8 || !this.company.isLegal || this.isDiler)
      );
    },
    disableAddPhoneButtonInNewContact() {
      if (this.company.contact.phones !== null) {
        if (this.company.contact.phones.length == 0) {
          return true;
        } else {
          return this.company.contact.phones.every((elem) => elem.length > 0);
        }
      } else {
        return false;
      }
    },
    disableAddEmailButtonInNewContact() {
      if (this.company.contact.emails !== null) {
        if (this.company.contact.emails.length == 0) {
          return true;
        } else {
          return this.company.contact.emails.every((elem) => elem.length > 0);
        }
      } else {
        return false;
      }
    },
    isValidFormData() {
      return !(
        this.company.formOwnership !== null && this.company.formOwnership !== undefined &&
        (this.company.kpp.length === 9 || !this.company.isLegal) &&
        this.company.customerCategory !== null &&
        this.company.source !== null &&
        this.company.country.length > 3 &&
        this.company.city.length > 2 &&
        this.company.directions.length > 0 &&
        this.company.cause.length > 5 &&
        this.company.contact.name.length > 3 &&
        this.company.contact.post.length > 3 &&
        this.company.contact.phones[0].length > 6
      );
    },
  },
  methods: {
    fetchData() {
      this.activities = localStorage.getItem("user-token");
      this.cleanCompanyData = JSON.parse(JSON.stringify(this.company));
      this.cleanData = JSON.parse(JSON.stringify(this.$data));
      if (this.isDiler && this.user_directions !== null && this.user_directions.length == 1) {
        if (this.user_directions[0] == "000000001") {
          this.list_dirs = [
            "ВТ с ДВС",
            "ВТ с эл. двиг.",
          ]
        }
        if (this.user_directions[0] == "000000003") {
          this.list_dirs = [
            "ДСТ",
            "Экскаваторы",
            "Фронтальные погрузчики",
            "Мини погрузчики",
          ]
        }
      }
      this.getListFirm();
    },
    search(isNew = false) {
      if (this.validateInn()) {
        this.loading = true;
        axios({
          method: "get",
          url: ADRESS + "checkfirm",
          params: { inn: this.searchINN, kpp: this.searchKPP },
          headers: { Authorization: this.activities },
        })
          .then((response) => {
            if ("error_text" in response.data) {
              this.companiesData = [];
              if (isNew !== true) {
                this.$notify({
                  title: "Ошибка",
                  type: "warning",
                  message: response.data.error_text,
                });
                this.AvailableNumberRequests =
                  response.data.AvailableNumberRequests;
              } else {
                this.isNewCompany = true;
                this.isCreatingCompany = true;
                this.company.inn = this.searchINN;
                this.company.kpp = this.searchKPP;
                this.company.uik = null;
                this.getOptionsCompany();
              }
            } else {
              if (isNew === true) {
                this.$notify({
                  title: "Ошибка",
                  type: "warning",
                  message: "Данный контрагент есть в базе",
                });
              }
              this.companiesData = response.data.firms;
              this.companiesData.forEach((element) => {
                element["Закрепление"] = element["Закрепление"].split("\n");
                element["Взаимоотношения"] =
                  element["Взаимоотношения"].split("\n");
                element["Сделки"] = element["Сделки"].split("\n");
              });
              this.isNewCompany = false;
              this.AvailableNumberRequests =
                response.data.AvailableNumberRequests;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            handelerErrors(err);
          });
      } else {
        this.$notify.error({
          title: "Ошибка",
          message: "ИНН не верный",
        });
      }
    },
    findById(id, kpp) {
      this.loading = true;
      const params = this.company.isLegal ? "&kpp=" + kpp : "";
      const url = `${this.baseUrl}/api-crm/find-by-id/?id=` + id + params;
      axios
        .get(url)
        .then((response) => {
          this.loading = false;
          this.daDataObj = response.data.data;
          this.autoCompleteForm();
        })
        .catch((err) => {
          this.loading = false;
          handelerErrors(err);
        });
    },
    createCompany() {
      if (this.searchINN.length >= 12 && this.company.isLegal) {
        this.isSearchINNLengthMax = true

        setTimeout(() => (this.isSearchINNLengthMax = false), 6000)
      } else {
        this.search(true);
      }
    },
    openDialogForReCreate(id) {
      const company = this.companiesData[id];
      this.company.shortName = company["НаименованиеПолное"];
      this.company.inn = company["ИНН"];
      this.company.kpp = company["КПП"];
      this.company.uik = company["УИК"];
      this.getOptionsCompany();
    },
    getOptionsCompany() {
      this.fullscreenLoading = true;
      axios({
        method: "get",
        url: ADRESS + "get-options-company",
        headers: { Authorization: this.activities },
      })
        .then((response) => {
          this.optionsCompany = response.data;
          let isAvailable =
            parseInt(this.optionsCompany.fixed, 10) +
            parseInt(this.optionsCompany.inProcess, 10);
          if (isAvailable < parseInt(this.optionsCompany.limit, 10)) {
            this.company.parkTechs = response.data.parkTechs;
            this.fullscreenLoading = false;
            this.dialogVisible = true;
            this.findById(this.company.inn, this.company.kpp);
            if (this.isDiler && this.user_directions.length == 1) {
              this.company.directions.push(this.user_directions[0]);
            }
          } else {
            this.fullscreenLoading = false;
            this.dialogVisible = false;
            this.$notify.error({
              title: "Ошибка",
              message: "Превышен лимит",
            });
          }
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    autoCompleteForm() {
      if (this.daDataObj.length !== 0) {
        this.company.shortName = this.daDataObj[0].value;
        this.company.fullName = this.daDataObj[0].data.name.full;
        this.company.formOwnership = this.optionsCompany.formsOwnership.find(
          (obj) => {
            return obj.name === this.daDataObj[0].data.opf.short;
          }
        );
        this.company.country = this.daDataObj[0].data.address.data.country;
        this.company.city = this.daDataObj[0].data.address.data.city == null ? '' : this.daDataObj[0].data.address.data.city;
        this.company.region =
          this.daDataObj[0].data.address.data.region_with_type;
      }
    },
    addPhoneInNewContact() {
      this.company.contact.phones.push("");
    },
    addMailInNewContact() {
      this.company.contact.emails.push("");
    },
    sendForFixing() {
      this.fullscreenLoading = true;
      axios({
        method: "post",
        url: ADRESS + "fixfirm",
        data: this.company,
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.fullscreenLoading = false;
          if (response.data.hasOwnProperty("error_text")) {
            this.$notify({
              title: "Ошибка",
              type: "warning",
              message: response.data.error_text,
            });
          } else {
            this.$notify.success({
              title: "Успешно",
              message: response.status,
            });
            Object.assign(this.$data, this.cleanData);
            this.cleanData = Object.assign({}, this.$data);
            this.getListFirm();
          }
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    validateInn() {
      let error = {};
      let inn = this.searchINN;
      var result = false;
      if (typeof inn === "number") {
        inn = inn.toString();
      } else if (typeof inn !== "string") {
        inn = "";
      }
      if (!inn.length) {
        error.code = 1;
        error.message = "ИНН пуст";
      } else if (/[^0-9]/.test(inn)) {
        error.code = 2;
        error.message = "ИНН может состоять только из цифр";
      } else if ([10, 12].indexOf(inn.length) === -1) {
        error.code = 3;
        error.message = "ИНН может состоять только из 10 или 12 цифр";
      } else {
        var checkDigit = function (inn, coefficients) {
          var n = 0;
          for (var i in coefficients) {
            n += coefficients[i] * inn[i];
          }
          return parseInt((n % 11) % 10);
        };
        switch (inn.length) {
          case 10:
            var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n10 === parseInt(inn[9])) {
              result = true;
            }
            break;
          case 12:
            var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
              result = true;
            }
            break;
        }
        if (!result) {
          error.code = 4;
          error.message = "Неправильное контрольное число";
        }
      }
      return result;
    },
    cleanCompany() {
      this.cleanCompanyData.isLegal = this.company.isLegal;
      this.cleanCompanyData.contact = {
        name: "",
        post: "",
        phones: [""],
        emails: [""],
      };
      Object.assign(this.company, this.cleanCompanyData);
    },
    getListFirm() {
      this.fullscreenLoading = true;
      axios({
        method: "get",
        url: ADRESS + "getlistfirm",
        headers: { Authorization: this.activities },
      })
        .then((response) => {
          this.firms = response.data.firms.sort((a, b) => {
            return a["firm"].localeCompare(b["firm"]);
          });
          this.fixed = parseInt(response.data.fixed, 10);
          this.inProcess = parseInt(response.data.inProcess, 10);
          this.limit = parseInt(response.data.limit, 10);
          this.fullscreenLoading = false;
          this.AvailableNumberRequests = response.data.AvailableNumberRequests;
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
  },
};
</script>

<style lang="scss">
.checkFirm {
  .el-form-item__content span {
    font-size: 12px;
  }

  .switch-legal {
    display: flex;
    align-items: center;

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }

  .input__dropdown-info {
    border-left: 4px solid currentColor;
    margin-top: 4px;
    padding: 2px 5px;
    color: #E6A23C
  }

  .el-container {
    margin-top: 0px;
  }

  .companies {
    margin: 0 30px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
  }

  .company {
    margin: 20px 0;
    padding: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
    border-top: 1px solid #333;

    span {
      font-size: 12px;
      line-height: 18px;
    }

    strong {
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
    }

    h2 {
      font-size: 20px;
      line-height: 24px;
    }

    h3 {
      font-size: 16px;
      line-height: 20px;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    ul {
      li {
        margin-left: 25px;
        font-size: 12px;
        line-height: 18px;
      }
    }

    .button-re-create {
      margin-top: 15px;
    }
  }

  .el-input {
    max-width: 350px;
    width: 80%;
  }

  .checkFirm-buttons {
    max-width: 450px;
  }

  @media (max-width: 650px) {
    .companies {
      margin: 0 5px;
      margin-bottom: 50px;
    }

    .company {
      h2 {
        font-size: 16px;
        line-height: 20px;
      }

      h3 {
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
  }
}

.checkFirm__limits {
  padding-left: 15px;
  display: flex;
  margin: 10px 0;

  .el-statistic {
    display: flex;

    .head {
      margin-bottom: 0;
      line-height: 22px;
    }

    .title {
      font-size: 15px;
    }

    .number {
      font-size: 17px;
      font-weight: 700;
      padding-left: 10px;
    }
  }
}

.clients-limit__container {
  display: flex;
  font-size: 15px;
}

.dialog-fix-company {
  h3 {
    display: block;
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 15px;
  }

  h4 {
    display: block;
    margin: 10px 0;
    font-size: 13px;
    font-weight: bold;
  }

  .el-input {
    width: 100%;
    max-width: 450px;
  }

  .el-input__inner,
  .el-textarea__inner {
    color: #000;

    &::placeholder {
      color: #000;
    }
  }

  .el-dialog__body {
    padding-top: 0;
  }

  .form-fix-company {
    color: #000;

    .v-select {
      margin: 15px 0;
    }

    .el-switch {
      height: auto;
    }

    .el-switch__label--right {
      @media (max-width: 650px) {
        margin-left: 0;
      }
    }
  }

  .form-fix-company__header {
    margin-bottom: 10px;
    display: block;

    h2 {
      font-size: 18px;
      display: inline;
      margin-bottom: 15px;

      @media (max-width: 650px) {
        font-size: 15px;
      }
    }
  }

  .form-fix-company__directions {
    display: flex;
    flex-wrap: wrap;
  }

  .form-fix-company__direction {
    width: 300px;
    margin-bottom: 5px;

    @media (max-width: 650px) {
      span {
        font-size: 12px;
      }
    }
  }

  .form-fix-company__input {
    display: block;
    margin-bottom: 10px;
  }

  .form-fix-company__switch {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {
      display: flex;
      margin-right: 10px;

      @media (max-width: 650px) {
        font-size: 12px;
      }
    }
  }

  .form-fix-company__textarea {
    margin-bottom: 10px;
    width: 100%;

    @media (max-width: 650px) {

      span,
      textarea {
        font-size: 12px;
      }
    }
  }

  .form-fix-company__contact {
    .el-input {
      margin-bottom: 10px;
    }
  }

  .form-fix-company__phones,
  .form-fix-company__mails {
    .el-button {
      margin-bottom: 10px;
    }
  }

  .form-fix-company__tech-park .el-row {
    >span {
      display: block;
      margin-bottom: 5px;
    }
  }
}

.red-border {
  &.el-date-editor--datetimerange {
    border: 2px solid #f56c6c !important;
  }

  .vs__dropdown-toggle {
    border: 2px solid #f56c6c !important;
  }

  >input {
    border: 2px solid #f56c6c !important;
  }

  textarea {
    border: 2px solid #f56c6c !important;
  }
}
</style>
