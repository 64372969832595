<template>
  <div class="call-view-component" v-if="call !== null">
    <el-dialog title="Входящее обращение" :visible.sync="dialogVisibleOuter" @closed="closeDialog" width="96%"
      :close-on-click-modal="false">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="Основное" name="first">
          <div class="call-view">
            <el-row>
              <span>Дата:</span>
              <strong>{{ call["ДатаДляСортировки"] }}</strong>
            </el-row>
            <el-row>
              <span>Контактное лицо:</span>
              <strong v-if="call['КонтактноеЛицо'] !== null">{{
                call["КонтактноеЛицо"]
              }}</strong>
            </el-row>
            <el-row>
              <span>Номер телефона:</span>
              <el-link :href="'tel:+' + call['НомерТелефона']">+{{ call["НомерТелефона"] }}</el-link>
            </el-row>
            <el-row v-if="call['Почта']">
              <span>Почта:</span>
              <el-link :href="'mailto:+' + call['Почта']">{{
                call["Почта"]
              }}</el-link>
            </el-row>
            <el-row v-if="call['Город']">
              <span>Город:</span>
              <strong>{{ call["Город"] }}</strong>
            </el-row>
            <el-row v-if="call['ТипОбращения']">
              <span>Тип обращения:</span>
              <strong>{{ call["ТипОбращения"] }}</strong>
            </el-row>
            <el-row>
              <el-select v-model="call['Контрагент']" value-key="Наименование" autocomplete="true" :filterable="true"
                :clearable="true" size="mini" :popper-append-to-body="false" popper-class="call-view-select__zero-left"
                placeholder="Контрагент">
                <el-option v-for="item in firms" :key="item['УИК']" :label="item['Наименование']" :value="item">
                </el-option>
              </el-select>
            </el-row>
            <el-row>
              <el-select v-model="call['ТемаОбращения']" value-key="name" autocomplete="true" :filterable="true"
                :clearable="true" size="mini" :popper-append-to-body="false" popper-class="call-view-select__zero-left"
                placeholder="Тема обращения">
                <el-option v-for="item in lists['Темы']" :key="item.id" :label="item.name" :value="item">
                </el-option>
              </el-select>
            </el-row>
            <el-row>
              <el-select v-model="call['НаправлениеОбращения']" value-key="name" :filterable="true" :clearable="true"
                size="mini" :popper-append-to-body="false" popper-class="call-view-select__zero-left"
                placeholder="Направление">
                <el-option v-for="(item, index) in lists['Направления']" :key="index" :label="item['name']" :value="item">
                </el-option>
              </el-select>
            </el-row>
            <el-row>
              <el-select v-model="call['ТипТехники']" value-key="name" autocomplete="true" :filterable="true"
                :clearable="true" size="mini" :popper-append-to-body="false" popper-class="call-view-select__zero-left"
                placeholder="Тип Техники">
                <el-option v-for="item in lists['ТипыТехники']" :key="item.id" :label="item.name" :value="item">
                </el-option>
              </el-select>
            </el-row>
            <el-row>
              <el-select v-model="call['Бренд']" value-key="name" autocomplete="true" :filterable="true" :clearable="true"
                size="mini" :popper-append-to-body="false" popper-class="call-view-select" placeholder="Бренд">
                <el-option v-for="item in lists['Бренды']" :key="item.id" :label="item.name" :value="item">
                </el-option>
              </el-select>
            </el-row>
            <el-row>
              <el-input v-model="call['Техника']" size="mini" placeholder="Модель" />
            </el-row>
            <el-row>
              <el-input type="textarea" size="mini" :autosize="true" resize="none" placeholder="Содержание обращения"
                v-model="call['СодержаниеОбращения']" />
            </el-row>
            <el-row v-if="call['Файлы'].length > 0">
              <ul class="call-view__files">
                <span>Файлы:</span>
                <li v-for="(file, index) in call['Файлы']" :key="index" @click="onClickDownloadFile(file['УИК'])">
                  {{ file["Название"] }}
                </li>
              </ul>
            </el-row>
            <el-row>
              <el-select v-model="call['ПричинаЗакрытияСделки']" value-key="name" autocomplete="true" :filterable="true"
                :clearable="true" size="mini" :popper-append-to-body="false" popper-class="call-view-select__zero-left"
                placeholder="Причина">
                <el-option v-for="item in lists['ПричиныЗакрытия']" :key="item.id" :label="item.name" :value="item">
                </el-option>
              </el-select>
            </el-row>
            <el-row>
              <el-input size="mini" v-model="call['ПричинаКомментарий']" placeholder="Комментарий по закрытию" />
            </el-row>
            <el-button :disabled="isClosing" type="primary" @click="saveCall">Сохранить</el-button>
            <el-button type="warning" @click="dialogVisibleRedirect = true">Перенаправить</el-button>
            <el-dialog width="100%" :fullscreen="true" title="Перенаправить входящее"
              :visible.sync="dialogVisibleRedirect" append-to-body>
              <el-row>
                <v-select v-model="user" label="name" maxHeight="300px" :options="users"
                  placeholder="Выберете менеджера"></v-select>
                <!-- <el-select
                  v-model="user"
                  value-key="name"
                  :filterable="true"
                  :clearable="true"
                  size="mini"
                  :popper-append-to-body="false"
                  popper-class="call-view-select__zero-left"
                  placeholder="Выберете менеджера"
                >
                  <el-option
                    v-for="(item, index) in users"
                    :key="index"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select> -->
              </el-row>
              <el-button class="call-view__button-redirect" :disabled="user === null || user == ''" type="warning"
                @click="redirectCall">Перенаправить</el-button>
            </el-dialog>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Обсуждения" name="second" class="tab-discussions">
          <el-timeline :reverse="true">
            <el-timeline-item v-for="(item, index) in call['Обсуждения']" :key="index" :timestamp="item['ДатаСоздания']"
              placement="top">
              <el-card>
                <p>{{ item["ТекстСообщения"] }}</p>
                <el-row type="flex" justify="end">
                  <span class="date-reminder" v-if="item['ДатаНапоминания']">Дата напоминания:
                    {{ item["ДатаНапоминания"] | removeTime }}</span>
                </el-row>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <el-row>
            <el-date-picker v-model="dateReminder" size="mini" format="dd.MM.yyyy" value-format="dd.MM.yyyy HH:mm:ss"
              type="date" placeholder="Дата напоминания">
            </el-date-picker>
          </el-row>
          <el-row>
            <el-checkbox size="mini" v-model="isReminder">Напомнить</el-checkbox>
          </el-row>
          <el-row class="relation-create__row-comment">
            <el-input v-model="currentComment" size="mini" @keyup.enter.native="addCommentToRelation"
              placeholder="Введите комментарий" />
            <el-button :disabled="isCantSent" type="success" size="small" icon="el-icon-s-promotion" circle
              @click="addCommentToRelation"></el-button>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { ADRESS } from "@/store/actions/auth";
import downloadjs from "downloadjs";
export default {
  name: "vCallView",
  data() {
    return {
      dialogVisibleOuter: false,
      call: null,
      activeTab: "first",
      currentComment: "",
      dateReminder: null,
      isReminder: false,
      dialogVisibleRedirect: false,
      users: null,
      user: null,
      firms: [],
    };
  },
  props: {
    propCall: Object,
    activities: String,
    lists: Object,
  },
  watch: {
    propCall(call) {
      if (call !== null) {
        this.dialogVisibleOuter = true;
        this.call = call;
      }
    },
  },
  created() { },
  mounted() {
    this.getListUsers();
    this.getListFirm();
  },
  computed: {
    isClosing() {
      if (
        this.call["ПричинаЗакрытияСделки"] === null &&
        this.call["ПричинаКомментарий"].length !== 0
      ) {
        return true;
      }
      if (
        this.call["ПричинаЗакрытияСделки"] === null &&
        this.call["ПричинаКомментарий"].length === 0
      ) {
        return false;
      }
      if (
        this.call["ПричинаЗакрытияСделки"].length === 0 &&
        this.call["ПричинаКомментарий"].length !== 0
      ) {
        return true;
      }
      if (
        this.call["ПричинаЗакрытияСделки"].length === 0 &&
        this.call["ПричинаКомментарий"].length === 0
      ) {
        return false;
      }

      if (
        this.call["ПричинаЗакрытияСделки"] !== null &&
        this.call["ПричинаКомментарий"].length === 0
      ) {
        return true;
      }
      if (
        this.call["ПричинаЗакрытияСделки"] !== null &&
        this.call["ПричинаКомментарий"].length !== 0
      ) {
        return false;
      }
      if (
        this.call["ПричинаЗакрытияСделки"].length !== 0 &&
        this.call["ПричинаКомментарий"].length === 0
      ) {
        return true;
      }
      if (
        this.call["ПричинаЗакрытияСделки"].length !== 0 &&
        this.call["ПричинаКомментарий"].length !== 0
      ) {
        return false;
      }
      return false;
    },
    isCantSent() {
      if (this.currentComment.length < 4) {
        return true;
      }
      if (this.isReminder && this.dateReminder === null) {
        return true;
      }
      return false;
    },
  },
  filters: {
    removeTime(date) {
      return moment(date, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY");
    },
  },
  methods: {
    closeDialog() {
      this.activeTab = "first";
      this.currentComment = "";
      this.dateReminder = null;
      this.isReminder = false;
      this.$emit("closeDialog");
    },
    onClickDownloadFile(uik) {
      axios
        .get(ADRESS + "view-file-appeal?uik=" + uik, {
          headers: {
            Authorization: this.activities,
          },
        })
        .then((response) =>
          downloadjs(response.data.value.body, response.data.value.name)
        )
        .catch((err) => {
          console.log(err);
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
      console.log(uik);
    },
    saveCall() {
      const loading = this.$loading({
        lock: true,
      });
      axios({
        method: "post",
        url: ADRESS + "editappeal",
        data: this.call,
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.closeDialog();
          console.log(response.data);
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    addCommentToRelation() {
      if (this.currentComment.length > 3) {
        const comment = {
          ТекстСообщения: this.currentComment,
          ДатаСоздания: moment().format("DD.MM.YYYY HH:mm:ss"),
          ДатаНапоминания: this.dateReminder,
          УИК: this.call["УИК"],
          Напомнить: this.isReminder,
        };
        const loading = this.$loading({
          lock: true,
        });
        axios({
          method: "post",
          url: ADRESS + "create-message-appeal",
          data: comment,
          headers: {
            Authorization: this.activities,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              this.currentComment = "";
              this.dateReminder = null;
              this.call["Обсуждения"].unshift(comment);
              loading.close();
            }
          })
          .catch((err) => {
            console.log(err);
            loading.close();
            this.$notify.error({
              title: "Ошибка",
              message: err,
            });
          });
      }
    },
    redirectCall() {
      const loading = this.$loading({
        lock: true,
      });
      axios({
        method: "post",
        url: ADRESS + "redirectappeal",
        data: {
          УИК: this.call["УИК"],
          УИКИсполнителя: this.user.id,
        },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.dialogVisibleRedirect = false;
            this.dialogVisibleOuter = false;
            this.closeDialog();
            this.$emit("onRedirect", this.call["УИК"]);
            this.user = null;
            loading.close();
            this.$notify.success({
              title: "Успешно",
              message: "Перенаправлено",
            });
          }
        })
        .catch((err) => {
          this.user = null;
          this.dialogVisibleRedirect = false;
          console.log(err);
          loading.close();
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    getListUsers() {
      axios({
        method: "get",
        url: ADRESS + "getlistusers",
        headers: { Authorization: this.activities },
      }).then((response) => {
        this.users = response.data.value.listusers.reduce((o, i) => {
          if (!o.find((v) => v.id == i.id)) {
            o.push(i);
          }
          return o;
        }, []);
      });
    },
    getListFirm() {
      this.fullscreenLoading = true;
      axios({
        method: "get",
        url: ADRESS + "getlistfirm",
        headers: { Authorization: this.activities },
      })
        .then((response) => {
          this.firms = response.data.firms.sort((a, b) => {
            return a["firm"].localeCompare(b["firm"]);
          });
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.fullscreenLoading = false;
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.call-view-component {
  .el-dialog__body {
    padding-top: 0;

    @media (max-width: 550px) {
      padding: 0 10px 20px;
    }
  }
}

.call-view {

  .vs__selected,
  .vs__search {
    font-size: 12px;
    white-space: nowrap;
  }

  span,
  strong {
    font-size: 12px;
    color: #000;
  }

  .el-button span {
    color: #ffffff;
  }

  .el-row {
    margin: 7px 0;

    >span {
      display: block;
    }

    .el-link span {
      font-weight: bold;
      color: #007090;
      text-decoration: underline;
    }
  }

  .call-view__files {
    list-style-type: none;

    li {
      font-weight: bold;
      font-size: 12px;
      color: #007090;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .el-select {
    width: 100%;
  }
}

.tab-discussions {
  .el-timeline-item__timestamp {
    font-size: 11px;
  }

  .el-card__body>* {
    font-size: 12px;
  }

  .date-reminder {
    display: block;
    margin-top: 5px;
    font-size: 10px;
  }

  span {
    font-size: 12px;
  }

  .relation-create__row-comment .el-input {
    width: calc(100% - 37px);
    margin-right: 5px;
  }
}

.call-view__button-redirect {
  margin-top: 30px;
}

@media (max-width: 550px) {
  .call-view-select__zero-left {
    left: 5px !important;

    span {
      font-size: 12px;
    }
  }

  .call-view-select {
    span {
      font-size: 12px;
    }
  }
}
</style>