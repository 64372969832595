<template>
  <div class="create-offer offer-kz" v-loading.fullscreen.lock="fullscreenLoading">
    <div v-if="!isViewMode" class="select-model">
      <v-select v-model="category" label="name" :options="categories" placeholder="Выберите категорию"></v-select>
      <v-select v-if="computedBrands.length > 0 || brand !== null" v-model="brand" label="name" :options="computedBrands"
        placeholder="Выберите бренд"></v-select>
      <v-select v-if="computedModels.length > 0 || model !== null" @input="onChangeModel" v-model="model"
        label="product_model" :options="computedModels" placeholder="Выберите модель"></v-select>
      <v-select v-if="masts.length > 0 || mast !== null" @input="updateMast" v-model="mast" label="name" :options="masts"
        placeholder="Выберите мачту"></v-select>
      <el-button v-show="false" :disabled="disableButton" icon="el-icon-plus">Создать</el-button>
    </div>
    <div class="mobile-forms" v-if="hasData && !isViewMode">
      <div>
        <span>Компания:</span>
        <el-input type="textarea" autosize placeholder="" v-model="nameCompany" />
      </div>
      <div>
        <span>КП действительно до:</span>
        <el-date-picker v-model="dateBefore" type="date" format="dd.MM.yyyy" value-format="dd.MM.yyyy" size="mini"
          placeholder="Выберите день" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div>
        <span>Дополнительное оснащение:</span>
        <el-input type="textarea" autosize placeholder="" v-model="equipment" />
      </div>
      <div>
        <span>Условия поставки:</span>
        <el-input type="textarea" autosize placeholder="" v-model="delivery" />
      </div>
      <div>
        <span>Гарантия:</span>
        <el-input type="textarea" autosize placeholder="" v-model="guarantee" />
      </div>
      <div>
        <span>Срок поставки:</span>
        <el-input type="textarea" autosize placeholder="" v-model="deliveryTime" />
      </div>
      <div>
        <span>Условия оплаты:</span>
        <el-input type="textarea" autosize placeholder="" v-model="payment" />
      </div>
      <div>
        <span>Цена :</span>
        <el-input type="text" v-model="price" maxlength="99" show-word-limit />
      </div>
    </div>
    <template v-if="hasData">
      <div class="create-offer-header">
        <el-button v-if="!isViewMode" class="button-create" @click="printPdf($data)" icon="el-icon-s-opportunity"
          type="primary" size="small" :disabled="isValidData">Создать PDF</el-button>
        <el-button v-if="isViewMode" class="button-create" @click="printPdf($data)" icon="el-icon-s-opportunity"
          type="primary" size="small">Печать PDF</el-button>
        <el-button v-if="!isViewMode" @click="isEdit = !isEdit" type="success" :icon="isEditButtonIcon" size="small">{{
          isEditButton }}</el-button>
      </div>
      <div class="container-pdf" :class="{ containerPdfEdit: isEdit }">
        <div class="container">
          <div class="header">
            <div class="header-left">
              <img class="logo" src="/assets/images/kz_logo.svg" alt="" />
            </div>
            <div class="header-right">
              <div class="title-container">
                <span class="title">Коммерческое предложение № <template v-if="!isEdit">{{ number }}</template><input
                    v-else v-model="number" type="text" /></span>
                <span class="about"><strong>ТОО «Спецтехника и шины» </strong><span class="margin-l-r"> для
                  </span><strong v-if="!isEdit">{{ nameCompany }}</strong><input v-else v-model="nameCompany"
                    type="text" /></span>
                <span class="about">Действителен до:
                  <template v-if="!isEdit">{{ dateBefore }}</template>
                  <el-date-picker v-else v-model="dateBefore" type="date" format="dd.MM.yyyy" value-format="dd.MM.yyyy"
                    size="mini" placeholder="Выберите день" :picker-options="pickerOptions">
                  </el-date-picker></span>
              </div>
              <div class="branch-container">
                <div class="branch-location">
                  <img class="logo" src="/assets/images/location.png" alt="" />
                  <span>Казахстан, Алматы, ул. Бурундайская, 93А</span>
                </div>
                <div class="branch-phone">
                  <img class="logo" src="/assets/images/phone.png" alt="" />
                  <span>+7 (705) 804 81 80</span>
                </div>
                <div class="branch-mail">
                  <img class="logo" src="/assets/images/mail.png" alt="" />
                  <span>mail@forklifts.kz</span>
                </div>
              </div>
            </div>
          </div>
          <div class="main">
            <div class="main-first">
              <div class="main-first__left">
                <div class="brand-container">
                  <img class="logo" :src="baseUrl + productBrandLogo" alt="" />
                </div>
                <div v-html="productBrandContent" class="brand-content"></div>
              </div>
              <div class="main-first__right">
                <img :src="baseUrl + productPhoto" alt="" />
              </div>
            </div>
            <div class="attributes-container">
              <span class="product-name">{{ productName }}</span>
              <span class="attributes-title">характеристики:</span>
              <div v-if="!isEdit" class="attributes-list">
                <template v-for="(item, index) in attributes">
                  <div :key="index" v-if="item[1] !== undefined && item[1].length > 0 && index < 24
                    " class="attributes-item">
                    <div v-if="!isEdit" class="attributes-key">
                      <p>{{ item[0] }}:</p>
                    </div>
                    <input v-else v-model="item[0]" type="text" class="attribute-input-key" />
                    <div class="attributes-dots"></div>
                    <div v-if="!isEdit" class="attributes-value">
                      <p>{{ item[1] }}</p>
                    </div>
                    <input v-else v-model="item[1]" type="text" class="attribute-input-item" />
                  </div>
                </template>
              </div>
              <div v-if="isEdit" class="attributes-list">
                <template v-for="(item, index) in attributes">
                  <div :key="index" v-if="item[1].length > 0 && index < 24" class="attributes-item">
                    <span v-if="!isEdit" class="attributes-key">{{ item[0] }}:</span>
                    <input v-else v-model="item[0]" type="text" class="attribute-input-key" />
                    <div class="attributes-dots"></div>
                    <span v-if="!isEdit" class="attributes-value">{{ item[1] }}
                    </span>
                    <input v-else v-model="item[1]" type="text" class="attribute-input-item" />
                    <el-button @click="removeEl(index)" class="button-delete" type="danger" icon="el-icon-delete" circle
                      size="mini"></el-button>
                  </div>
                </template>
              </div>
              <el-button v-if="isEdit" @click="addEl" :disabled="attributes.length > 25" type="success" circle size="mini"
                icon="el-icon-plus"></el-button>
            </div>
            <div class="equipment">
              <span class="equipment-title">Дополнительное оснащение:</span>
              <p v-if="!isEdit" class="equipment-content">{{ equipment }}</p>
              <p v-else>
                <input v-model="equipment" type="text" class="equipment-content" />
              </p>
            </div>
            <div class="line"></div>
            <div class="footer">
              <div class="footer-row">
                <div class="supply">
                  <strong class="title-flex">Условия поставки:</strong>
                  <span v-if="!isEdit">{{ delivery }}</span>
                  <input v-else v-model="delivery" type="text" />
                </div>
                <div class="service">
                  <strong class="title-flex">Гарантия:</strong>
                  <span v-if="!isEdit">{{ guarantee }}</span>
                  <input v-else v-model="guarantee" type="text" />
                </div>
              </div>
              <div class="footer-row footer-row__second">
                <div class="footer-col footer-col__first">
                  <div>
                    <span class="strong">Срок поставки: </span>
                    <span v-if="!isEdit">{{ deliveryTime }}</span>
                    <input v-else v-model="deliveryTime" type="text" />
                  </div>
                  <span class="price">Цена: <template v-if="!isEdit">{{ price }}</template><input v-else v-model="price"
                      type="text" /></span>
                </div>
                <div class="footer-col">
                  <span class="strong">Условия оплаты: </span>
                  <span v-if="!isEdit">{{ paymentResult }}</span>
                  <input v-else v-model="payment" type="text" />
                </div>
              </div>
              <div class="footer-row__manager">
                <span class="manager-name">Ваш персональный менеджер
                  <strong v-if="!isEdit">{{ managerName }}:</strong> <input v-else v-model="managerName"
                    type="text" /></span>
                <div class="manager-phone">
                  <img class="logo" src="/assets/images/phone.png" alt="" />
                  <span v-if="!isEdit">{{ managerPhone }}</span>
                  <input v-else v-model="managerPhone" type="text" />
                </div>
                <div class="manager-mail">
                  <img class="logo" src="/assets/images/mail.png" alt="" />
                  <span v-if="!isEdit">{{ managerMail }}</span>
                  <input v-else v-model="managerMail" type="text" />
                </div>
              </div>
              <div class="footer-warning">
                <span>Настоящее коммерческое предложение не является офертой и не
                  обязывает ТОО «Спецтехника и шины» к исполнению, окончательные
                  условия сотрудничества закрепляются сторонами в
                  договоре.</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="productContentHtml !== null && productContentHtml.length > 2" class="container">
          <div class="custom-page" v-html="productContentHtml"></div>
        </div>
        <div v-if="adaptive_content !== null && adaptive_content.length > 0" class="container">
          <div class="description-page">
            <div v-for="(item, index) in adaptive_content" :key="index">
              <div v-if="item.type == 'center_image'" class="description-page__one-image">
                <img :src="baseUrl + item.value" alt="" />
              </div>
              <div v-if="item.type == 'html_content'" class="description-page__html-content" v-html="item.value"></div>
              <div v-if="item.type == 'two_image'" class="description-page__two-image">
                <img :src="baseUrl + item.value.first" alt="" />
                <img :src="baseUrl + item.value.second" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { ADRESS, AUTH_LOGOUT } from "@/store/actions/auth";
import moment from "moment";
import Fetch from "./mixins/Fetch";
import { handelerErrors } from "@/plugins/utils"
export default {
  name: "OfferKz",
  components: {},
  mixins: [Fetch],
  data() {
    return {
      activities: "",
      categories: [],
      category: null,
      brands: [],
      brand: null,
      models: [],
      model: null,
      masts: [],
      mast: null,
      number: "",
      managerName: "",
      managerPhone: "",
      managerMail: "",
      nameCompany: "",
      dateBefore: "",
      equipment: "",
      delivery: "",
      deliveryTime: "",
      payment: "",
      guarantee: "",
      price: "",
      price_comment: "",
      productName: null,
      productBrandContent: null,
      productBrandLogo: null,
      productPhoto: null,
      attributes: null,
      hasData: false,
      isEdit: false,
      branch: null,
      user_id: "",
      oldAttributes: null,
      searchINN: "",
      timeout: null,
      isSelectCompany: false,
      newCompany: null,
      currentTabCompany: "1",
      fullscreenLoading: false,
      firms: [],
      firm: null,
      loading: false,
      productContentHtml: "",
      isViewMode: false,
      offer_id: "",
      pickerOptions: {
        disabledDate(time) {
          const dateMonth = new Date();
          dateMonth.setTime(Date.now() + 3600 * 1000 * 24 * 31);
          return time.getTime() < Date.now() || time.getTime() > dateMonth;
        },
        shortcuts: [
          {
            text: "1 недели",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
          {
            text: "2 недели",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 14);
              picker.$emit("pick", date);
            },
          },
          {
            text: "3 недели",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 21);
              picker.$emit("pick", date);
            },
          },
          {
            text: "4 недели",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 28);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      price_integer: 1000,
      currency: null,
      currencies: [],
      isVat: true,
      adaptive_content: null,
      bx_id: null
    };
  },
  mounted() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);

    if (urlParams.has('id')) {
      this.bx_id = urlParams.get('id');
    }
  },
  computed: {
    disableButton() {
      return (
        this.category == null ||
        this.brand == null ||
        this.model == null ||
        this.branch == null
      );
    },
    isEditButton() {
      return !this.isEdit ? "Редактировать" : "Посмотреть";
    },
    isEditButtonIcon() {
      return !this.isEdit ? "el-icon-edit" : "el-icon-view";
    },
    isValidData() {
      return (
        this.nameCompany.length < 2 ||
        this.dateBefore.length < 2 ||
        this.currency == null
      );
    },
    resultPrice() {
      return `${this.serializeNumber(this.price_integer, this.currency)} ${this.price_comment}`;
    },
    vat() {
      return this.isVat ? "с НДС" : "без НДС";
    },
    paymentResult() {
      let in_currency =
        "Оплата осуществляется по курсу ЦБ РФ на дату платежа, но не менее 65 рублей за 1$. ";
      return this.currency !== null &&
        this.currency !== undefined &&
        this.currency.id === 1
        ? this.payment + " " + in_currency
        : this.payment;
    },
    computedBrands() {
      if (
        this.models !== undefined &&
        this.brands !== undefined &&
        this.brands.length > 0
      ) {
        let brands_id = [...new Set(this.computedModels.map((a) => a.brand))];
        let brands = [...this.brands];
        if (this.category !== null) {
          brands = brands.filter((item) => brands_id.includes(item.id));
        }
        return brands;
      } else {
        return [];
      }
    },
    computedModels() {
      if (this.models !== undefined && this.models.length > 0) {
        let models = [...this.models];
        if (this.category !== null) {
          models = models.filter((item) => item.category === this.category.id);
        }
        if (this.brand !== null) {
          models = models.filter((item) => item.brand === this.brand.id);
        }
        return models;
      } else {
        return [];
      }
    },
  },
  methods: {
    fetchData() {
      this.clearData();
      this.setDefaultDate();
      this.getAllContent();
    },
    getAllContent() {
      axios.get(`${this.baseUrl}/api-crm/get-all-models/`).then((response) => {
        this.categories = response.data.categories;
        this.brands = response.data.brands;
        this.models = response.data.products;
        this.currencies = response.data.currencies;
        this.currency = this.currencies.find((el) => el.name == "RUB");
        let parameter = this.findGetParameter("open-offer");
        let isEdit = this.findGetParameter("is-edit") === "true";
        if (parameter !== null) {
          this.isViewMode = !isEdit;
          this.getOffer(parameter);
          this.offer_id = parameter;
        }
      });
    },
    sortingAttr(attr) {
      var arrAttr, attributes, colLeftLength, n;
      attributes = [...attr]
      n = 0;
      colLeftLength = 0;

      if (attributes.length % 2 === 1) {
        colLeftLength = Math.ceil(attributes.length / 2);
        n = attributes.length - colLeftLength + 1;
      } else {
        colLeftLength = Math.ceil(attributes.length / 2);
        n = attributes.length - colLeftLength;
      }

      arrAttr = [];

      for (var element, _pj_c = 0, _pj_a = attributes, _pj_b = _pj_a.length; _pj_c < _pj_b; _pj_c += 1) {
        element = _pj_a[_pj_c];
        arrAttr.push(element);

        if (attributes.length === arrAttr.length) {
          break;
        }

        arrAttr.push(attributes[n]);
        n = n + 1;

        if (attributes.length === arrAttr.length) {
          break;
        }
      }
      return arrAttr;
    },
    onChangeModel() {
      if (this.model !== null) {
        this.adaptive_content = null;
        axios({
          method: "get",
          url: `${this.baseUrl}/api-crm/get-model-content/`,
          params: {
            product: this.model.product_model,
          },
        })
          .then((response) => {
            this.adaptive_content = response.data.adaptive_content;
            this.productName = response.data.productName;
            this.productBrandContent = response.data.productBrandContent;
            this.productBrandLogo = response.data.productBrandLogo;
            this.productPhoto = response.data.productPhoto;
            this.productContentHtml =
              "content" in response.data ? response.data.content : null;
            this.masts = response.data.masts;
            const arr = response.data.attributes;
            this.attributes = arr.filter((el) => {
              return (
                el[0] !== null &&
                el[1] !== null &&
                el[0].length > 0 &&
                el[1].length > 0
              );
            });
            this.attributes = this.sortingAttr(this.attributes)
            this.addMast(this.attributes);
            this.hasData = true;
            this.getLocalStorage();
          })
          .catch((err) => {
            console.log(err);
            this.$notify.error({
              title: "Ошибка",
              message: err,
            });
          });
      }
    },
    SentAndSave() {
      this.attributes = this.attributes.filter(function (value, index, arr) {
        return index < 24;
      });

      let data = {
        category: this.category,
        brand: this.brand,
        model: this.model,
        branch: this.branch,
        nameCompany: this.nameCompany,
        dateBefore: this.dateBefore,
        equipment: this.equipment,
        delivery: this.delivery,
        deliveryTime: this.deliveryTime,
        payment: this.payment,
        guarantee: this.guarantee,
        price: this.price,
        currency: this.currency,
        isVat: this.isVat,
        price_integer: this.price_integer,
        attributes: this.attributes,
        mast: this.mast,
        companyUik: this.firm !== null ? this.firm["УИК"] : null,
        companyInn:
          this.firm !== null
            ? this.firm["ИНН"]
            : this.newCompany !== null
              ? this.newCompany["data"]["inn"]
              : "",
        companyKpp:
          this.firm !== null
            ? this.firm["КПП"]
            : this.newCompany !== null
              ? this.newCompany["data"]["kpp"] !== undefined
                ? this.newCompany["data"]["kpp"]
                : ""
              : "",
        productBrandContent: this.productBrandContent,
        productBrandLogo: this.productBrandLogo,
        productName: this.productName,
        productPhoto: this.productPhoto,
        managerName: this.managerName,
        managerPhone: this.managerPhone,
        managerMail: this.managerMail,
        adaptive_content: this.adaptive_content,
      };
      data.price = this.resultPrice;
      axios({
        method: "post",
        url: ADRESS + "createoffer",
        data: data,
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          data.number = response.data.НомерКП;
          this.printPdf(data);
        })
        .catch((err) => {
          console.log(err);
          handelerErrors(err);
        });
    },
    printPdf(data) {
      data.bx_id = this.bx_id;
      const loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading",
      });
      const headers = {
        "content-type": "application/json",
      };
      axios
        .post("/print-pdf/kz", data, { headers: headers, responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.pdf");
          document.body.appendChild(link);
          link.click();
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    getOffer(offer_id) {
      axios.get(`${ADRESS}get-offer?id=` + offer_id).then((response) => {
        this.hasData = true;
        this.category = this.categories.find(
          (item) => item.name === response.data.category
        );
        this.brand = this.brands.find(
          (item) => item.name === response.data.brand
        );
        this.model = this.models.find(
          (item) => item.product_model === response.data.model
        );
        this.currency = this.currencies.find(
          (el) => el.name == response.data.currency
        );
        this.managerName = response.data.managerName;
        this.managerPhone = response.data.managerPhone;
        this.managerMail = response.data.managerMail;
        this.dateBefore = moment(
          response.data.dateBefore,
          "DD.MM.YYYY hh:mm"
        ).format("DD.MM.YYYY");
        this.equipment = response.data.equipment;
        this.delivery = response.data.delivery;
        this.deliveryTime = response.data.deliveryTime;
        this.payment = response.data.payment;
        this.guarantee = response.data.guarantee;
        this.price = response.data.price;

        const arr = response.data.attributes;
        this.attributes = arr.filter((el) => {
          return (
            el[0] !== null &&
            el[1] !== null &&
            el[0].length > 0 &&
            el[1].length > 0
          );
        });
        this.addMast(this.attributes);
        this.getContentFromModel(response.data.mast);
        if (this.isViewMode === true) {
          this.nameCompany = response.data.nameCompany;
          this.number = response.data.number;
        } else {
          this.number = "XXXXXX";
        }
      });
    },
    getContentFromModel(mast) {
      axios
        .get(
          `${this.baseUrl}/api-crm/get-model-content/?product=${this.model.product_model}`
        )
        .then((response) => {
          this.masts = response.data.masts;
          this.mast = this.masts.find((el) => el.name == mast);
          this.productName = response.data.productName;
          this.productBrandLogo = response.data.productBrandLogo;
          this.productBrandContent = response.data.productBrandContent;
          this.productPhoto = response.data.productPhoto;
          this.adaptive_content = response.data.adaptive_content;
        });
    },
    findGetParameter(parameterName) {
      var result = null,
        tmp = [];
      window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
      return result;
    },
    getLocalStorage() {
      if (
        (localStorage.getItem("user-fio") !== null &&
          this.fioToFi(localStorage.getItem("user-fio")) == this.managerName) ||
        this.managerName === ""
      ) {
        if (localStorage.getItem("user-fio") !== null) {
          this.managerName = this.fioToFi(localStorage.getItem("user-fio"));
        }
        if (localStorage.getItem("user-phone") !== null) {
          this.managerPhone = this.numberWithMask(
            localStorage.getItem("user-phone")
          );
        }
        if (localStorage.getItem("user-mail") !== null) {
          this.managerMail = localStorage.getItem("user-mail");
        }
      }
    },
    addEl() {
      this.attributes.push(["Атрибут" + this.attributes.length, "1"]);
    },
    removeEl(index) {
      this.attributes.splice(index, 1);
    },
    fioToFi(str) {
      if (str.split(" ").length > 2) {
        const lastIndex = str.lastIndexOf(" ");
        return str.substring(0, lastIndex);
      } else {
        return str;
      }
    },
    numberWithMask(num) {
      return num.replace(
        /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2}).*/,
        "$1 ($2) $3-$4-$5"
      );
    },
    addMast(ar) {
      if (this.mast !== null && ar !== null) {
        let attributes = [...ar];
        this.mast.attributes.forEach((mast_attr) => {
          if (mast_attr[1] !== null) {
            let b = 5;
            if (attributes.some((item) => item[0] == mast_attr[0])) {
              let attr_index = attributes.findIndex(
                (item) => item[0] == mast_attr[0]
              );
              attributes[attr_index][1] = mast_attr[1];
            } else {
              b = b + 1;
              attributes.splice(b, 0, [mast_attr[0], mast_attr[1]]);
            }
          }
        });
        this.attributes = attributes;
      }
    },
    updateMast() {
      if (this.mast !== null && this.attributes !== null) {
        let attributes = [...this.attributes];
        this.mast.attributes.forEach((mast_attr) => {
          if (mast_attr[1] !== null) {
            let b = 5;
            if (attributes.some((item) => item[0] == mast_attr[0])) {
              let attr_index = attributes.findIndex(
                (item) => item[0] == mast_attr[0]
              );
              attributes[attr_index][1] = mast_attr[1];
            } else {
              b = b + 1;
              attributes.splice(b, 0, [mast_attr[0], mast_attr[1]]);
            }
          }
        });
        this.attributes = attributes;
      }
    },
    findById(queryString, cb) {
      clearTimeout(this.timeout);
      if (this.searchINN.length > 9) {
        this.loading = true;
        this.timeout = setTimeout(() => {
          axios({
            method: "get",
            url: `${this.baseUrl}/api-crm/find-by-id/`,
            params: { id: queryString },
          })
            .then((response) => {
              this.loading = false;
              const results = response.data.data;
              if (response.data.data.length === 0) {
                this.$notify.error({
                  title: "Ошибка",
                  message: "Компания не найдена!",
                });
              }
              cb(results);
            })
            .catch((err) => {
              this.loading = false;
              handelerErrors(err);
            });
        }, 2000);
      }
    },
    handleSelectNewCompany(item) {
      this.nameCompany = item.value;
      this.checkInnForDealerExclusive(item.data.inn);
      this.newCompany = item;
      this.isSelectCompany = true;
    },
    onChangeTabCompany() {
      this.cleanNewCompany();
      this.firm = null;
    },
    getListFirm() {
      this.fullscreenLoading = true;
      axios({
        method: "get",
        url: ADRESS + "getlistfirm",
        headers: { Authorization: this.activities },
      })
        .then((response) => {
          this.firms = response.data.firms.sort((a, b) => {
            return a["firm"].localeCompare(b["firm"]);
          });
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    cleanNewCompany() {
      this.nameCompany = "";
      this.searchINN = "";
      this.isSelectCompany = false;
      this.newCompany = null;
    },
    onChangeFirm() {
      if (this.firm !== null) {
        this.nameCompany = this.firm.firm;
        this.checkInnForDealerExclusive(this.firm["ИНН"]);
      }
    },
    setDefaultDate() {
      this.dateBefore = moment().add(14, "days").format("DD.MM.YYYY");
    },
    checkInnForDealerExclusive(inn) {
      let region = inn.substr(0, 2);
      let forbidden_list_forklift = ["12", "58", "34", "76"];
      let forbidden_list_dst = ["52"];
      if (this.brand.name == "TCM" || this.brand.name == "DALIAN") {
        if (forbidden_list_forklift.some((element) => element === region)) {
          this.$alert(
            `Данный регион является эксклюзивным для дилера по бренду ${this.brand.name}`,
            "Предупреждение",
            {
              confirmButtonText: "OK",
            }
          );
        }
      } else {
        if (this.brand.name == "LONKING") {
          if (forbidden_list_dst.some((element) => element === region)) {
            this.$alert(
              `Данный регион является эксклюзивным для дилера по бренду ${this.brand.name}`,
              "Предупреждение",
              {
                confirmButtonText: "OK",
              }
            );
          }
        }
      }
    },
    serializeNumber(number, currency) {
      return new Intl.NumberFormat("ru-RU", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: currency ? currency.name : "RUB",
      }).format(number);
    },
    clearData() {
      let data = {
        categories: [],
        category: null,
        brands: [],
        brand: null,
        models: [],
        model: null,
        masts: [],
        mast: null,
        number: "",
        managerName: "",
        managerPhone: "",
        managerMail: "",
        nameCompany: "",
        dateBefore: "",
        equipment: "",
        delivery: "",
        deliveryTime: "",
        payment: "",
        guarantee: "",
        price: "",
        productName: null,
        productBrandContent: null,
        productBrandLogo: null,
        productPhoto: null,
        attributes: null,
        hasData: false,
        isEdit: false,
        user_id: "",
        oldAttributes: null,
        searchINN: "",
        timeout: null,
        isSelectCompany: false,
        newCompany: null,
        currentTabCompany: "1",
        fullscreenLoading: false,
        firms: [],
        firm: null,
        loading: false,
        productContentHtml: "",
        isViewMode: false,
        offer_id: "",
        pickerOptions: {
          disabledDate(time) {
            const dateMonth = new Date();
            dateMonth.setTime(Date.now() + 3600 * 1000 * 24 * 31);
            return time.getTime() < Date.now() || time.getTime() > dateMonth;
          },
          shortcuts: [
            {
              text: "1 недели",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
                picker.$emit("pick", date);
              },
            },
            {
              text: "2 недели",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 14);
                picker.$emit("pick", date);
              },
            },
            {
              text: "3 недели",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 21);
                picker.$emit("pick", date);
              },
            },
            {
              text: "4 недели",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 28);
                picker.$emit("pick", date);
              },
            },
          ],
        },
        price_integer: 1000,
        currency: null,
        currencies: [],
        isVat: true,
        adaptive_content: null,
      };
      Object.assign(this.$data, data);
    },
  },
};
</script>

<style lang="scss">
.offer-kz {
  .container-pdf {
    .branch-container {
      width: 200px;
    }

    .header-left {
      width: 150px;
      min-width: auto;
      max-width: 100%;
      justify-content: flex-start;
      background-color: transparent;
    }

    .header-left .logo {
      width: 100%;
    }

    .header-right {
      width: calc(100% - 150px);
      min-width: auto;
      max-width: 100%;
    }

    .title-container {
      width: inherit;
      min-width: auto;
      max-width: 100%;
      flex-shrink: 1;
    }
  }
}
</style>