<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      { immediate: true }
    );
  },
  computed: {
    baseUrl() {
      return process.env.NODE_ENV === "production"
        ? "https://www.liftnet.ru"
        : "http://liftnet.ru:8000";
    },
  },
};
</script>